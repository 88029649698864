// Customizable Area Start
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import { GrBook } from 'react-icons/gr';
import { Box, withStyles, styled, Theme, Badge } from '@material-ui/core';

const appLogo1 = require('./appLogo1.png');
const appLogo2 = require('./appLogo2.png');
const categories = require('./categories.png');
const cart = require('./shopping-cart.svg');
const heart = require('./heart.svg');
const bell = require('./bell.svg');

import HeaderController, { Props } from './HeaderController.web';

const styles = (theme: Theme) => ({
  navMain: {
    position: 'sticky' as const,
    top: 0,
    zIndex: 9,
    display: 'flex',
    height: '88px',
    color: 'white',
    fontSize: '0.875em',
    background: 'linear-gradient(135deg, #3C7481 0%, #154955 100%)',
    padding: '12px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
    maxWidth: '5000px',
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box' as const,
  },
  listContainer: {
    maxWidth: 1440,
    margin: '0px 55px',
    display: 'inline-flex',
    listStyle: 'none',
    width: '100%',
    gap: '0.5rem',
    flexWrap: 'nowrap' as const,
    paddingLeft: 0,
    alignItems: 'center',
    '@media (min-width:1441px) and (max-width: 5100px)': {
          margin: '0 auto'
    },
    '@media (min-width:1080px) and (max-width: 1330px)': {
       margin: '0px 32px'
    }
  },
  searchBox: {
    '@media (min-width:1130px) and (max-width: 1330px)': {
      width: '300px'
    },
    '@media (min-width:600px) and (max-width: 1130px)': {
      width: '180px'
    }
  },
  linkButton: {
    minWidth: 100,
    borderRadius: 30,
    cursor: 'pointer',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center' as const,
    textDecoration: 'none',
    justifyContent: 'center',
    padding: 6,
    height: 25,
    margin: 'auto'
  },
  signupButton: {
    background: 'white',
    fontSize: 14,
    fontFamily: 'Inter'
  },
  signinButton: {
    background: '#FDC523',
    fontSize: 14,
    fontFamily: 'Inter'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
    margin: 'auto',
    textDecoration: 'none',
    color: 'white',
    cursor: 'pointer'
  },
  linkText: {
    fontSize: "18.46px",
    fontFamily: 'Inter',
    letterSpacing: 0.8,
    lineHeight: '22px',
    '@media (min-width:1280px) and (max-width: 1439px)': {
      fontSize: 16
    },
    '@media (min-width:900px) and (max-width: 1280px)': {
      fontSize: 14
    }
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '30px',
    margin: 'auto',
    border: '1px solid white',
    background: '#f2f2f23b',
    boxSizing: 'border-box' as const,
    height: 44,
  },
  input: {
    flex: 1,
    marginLeft: 4,
    color: 'white',
    width: '90%'
  },
  iconButton: {
    padding: 10,
    background: 'linear-gradient(180deg,#fff54b,#f4c952)',
    fontSize: '0.55rem',
    margin: 3,
  },
  searchButton: {
    background: 'linear-gradient(180deg,#fff54b,#f4c952)',
    color: "#fff",
    marginRight: 12,
    padding: "2px 10px",
    textTransform: "none" as const,
    cursor: 'pointer'
  },
  cart_badge:{
    '& .MuiBadge-colorPrimary': {
      background: 'linear-gradient(180deg,#fff54b,#f4c952)',
      color: 'rgba(0, 0, 0, 0.54)'
    },
  },
  cartIcon: {
    width: 27, 
    height: 27
  },
  appLogo1: {
    width: 54,
    height: 54,
    '@media (min-width:1080px) and (max-width: 1380px)': {
      width: 48,
      height: 48,
    }
  },
  appLogo2: {
    width: 103,
    height: 54,
    '@media (min-width:1080px) and (max-width: 1300px)': {
      width: 80,
      height: 54,
    }
  }
});

const IconButonStyled = styled(IconButton)({
  padding: 0,
});

class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const {search} = this.state;
    return (
      < >
        <nav className={classes.navMain}>
          <ul className={classes.listContainer}>
            <li>
              <Box
                className={classes.link}
                onClick={() => this.navigationToScreen('LandingPage')}
              >
                <img src={appLogo1} alt="Logo" className={classes.appLogo1}/>
                <img src={appLogo2} alt="Logo" className={classes.appLogo2} />
              </Box>
            </li>
            <li className={`searchWrapper ${classes.searchBox}`}>
              <Paper component="form" className={classes.root}>
                {search === "" && 
                <IconButton className={classes.iconButton} aria-label="menu">
                  <SearchIcon fontSize="inherit" />
                </IconButton>
                }
                <InputBase
                  className={classes.input}
                  placeholder="Search..."
                  type="text"
                  inputProps={{ 'aria-label': 'search' }}
                  value={this.state.search}
                  onChange={this.handleSearchValue}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      this.navigateToSearchResultScreen()
                    }
                  }}
                />
                {search !== "" ? 
                <Button 
                variant='outlined'
                className={classes.searchButton}
                onClick={this.navigateToSearchResultScreen}
                disabled={this.state.search.length < 2 }
                >Search</Button>
                 : null}
              </Paper>
            </li>
            <li style={{ marginLeft: 'auto', display: 'flex' }}>
              <Box
                className={classes.link}
                onClick={() =>
                  this.navigationToScreen('Categoriessubcategories')
                }
              >
                <img src={categories} width={20} height={20} alt="categories" />
                <div className={classes.linkText}>CATEGORIES</div>
              </Box>
            </li>
            {this.state.username !== 'USERNAME_TESTING_PURPOSE_ONLY' ? (
              <>
                <li style={{ display: 'flex' ,margin: "0px 10px"}}>
                  <Box
                    className={classes.link}
                    onClick={() => this.navigationToScreen('MyCourses')}
                  >
                    <GrBook fontSize={'20px'} color="inherit" />
                    <div className={classes.linkText}>MY COURSES</div>
                  </Box>
                </li>
                <li
                  style={{
                    margin: '0 10px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 10,
                    overflow: 'hidden',
                    flexWrap: 'nowrap' as const,
                    cursor: "pointer"
                  }}
                  onClick={() => this.navigationToScreen('UserProfile')}
                >
                  <Box
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    component={IconButonStyled}
                  >
                    <img
                      style={{
                        width: 28,
                        height: 28,
                        borderRadius: 30,
                        border: '2px #F0E5FF solid'
                      }}
                      src={this.state.profilePic}
                      alt="avatar"
                    />
                  </Box>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                    className={classes.linkText}
                  >
                    Hi, {this.state.username}
                  </p>
                </li>
              </>
            ) : (
              <>
                <li style={{ display: 'flex', marginLeft: '0 5px' }}>
                  <div
                    data-test-id="signupButton"
                    className={`${classes.linkButton} ${classes.signupButton}`}
                    onClick={() => this.navigationToScreen('Signup')}
                  >
                    SIGN UP
                  </div>
                </li>
                <li style={{ display: 'flex', marginRight: '5px' }}>
                  <div
                    data-test-id="loginButton"
                    className={`${classes.linkButton} ${classes.signinButton}`}
                    onClick={() => this.navigationToScreen('Login')}
                  >
                    LOG IN
                  </div>
                </li>
              </>
            )}
            <li
              style={{ display: 'flex', margin: '0 10px', cursor: 'pointer' }}
              onClick={() => this.navigationToScreen('ShoppingCartOrders')}
            >
              <Badge
                badgeContent={this.state.cartCount}
                color="primary"
                className={classes.cart_badge}
                invisible={this.state.cartCount === 0}
              >
                <img
                  src={cart}
                  className={classes.cartIcon}
                />
              </Badge>
            </li>
            <li
              style={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => this.navigationToScreen('Wishlist')}
            >
              <Badge
                badgeContent={this.state.wishlistCount}
                color="primary"
                className={classes.cart_badge}
                invisible={this.state.wishlistCount === 0}
              >
                <img
                  src={heart}
                  className={classes.cartIcon}
                />
              </Badge>
            </li>
            <li style={{ display: 'flex', margin: '0 10px' }}>
              <img
                src={bell}
                className={classes.cartIcon}
              />
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default withStyles(styles)(Header);
// Customizable Area End
