import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Color } from "@material-ui/lab/Alert";
import { getStorageData } from "../../../framework/src/Utilities";

const pageTitles:Record<string, string> = {
  privacypolicy: 'Privacy Policy',
  termsandconditions: 'Terms and Conditions',
  aboutus: 'About Us',
  contactus: 'Contact Us',
  faq: 'Frequently Asked Questions',
  refundpolicy: 'Refund Policy',
  howitworks: "How It Works",
  cancellationpolicy: "Cancellation Policy"
};

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

export interface ValidResponseType {
  message: string;
  data: object;
  scheduled: object;
}
interface SWTermsAndConditionsResponse {
  data: {
    id: string;
    attributes: {
      description: string;
    };
  }[];
}
interface TermsAndConditionsAttributes {
  description: string;
  created_on: string;
  updated_on: string;
}

export interface TermsAndConditions {
  data: TermsAndConditionsData;
}

export interface TermsAndConditionsData {
  id: string;
  type: string;
  attributes: TermsAndConditionsAttributes;
}

interface ErrorResponse {
  errors: Array<{ token: string }>;
}

interface TermsAndConditionsListResponse {
  data: TermsAndConditionsData;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userToken: string;
  loader: boolean;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  termsAndConditions: TermsAndConditionsData | undefined;
  isUpdate: boolean;
  termsAndConditionsId: string | number;
  description: string;
  swTermsandCondition: string;
  screenName: string;
  title: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsAndConditionsListApiCallId: string = "";
  getSWTermsAndConditionsApiCallId: string = "";
  showTermsAndConditionsApiCallId: string = "";
  submitTermsAndConditionsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage),
      getName(MessageEnum.SessionResponseData)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userToken: "",
      loader: false,
      isAlert: false,
      alertMsg: "",
      alertType: configJSON.successMsg,
      isUpdate: false,
      termsAndConditions: undefined,
      termsAndConditionsId: "",
      description: "",
      swTermsandCondition: "",
      screenName: "",
      title: "Terms and Conditions"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        if(!responseJson.errors) {
          this.apiSuccessCallBacksTermsandCondition(apiRequestCallId, responseJson);
        } else {
          this.parseErrors(responseJson, apiRequestCallId)
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let propsData = message.getData(getName(MessageEnum.NavigationIdMessage));
      if (propsData) {
        this.handleShowTermsAndConditions(propsData.termsAndConditionsId);
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.SessionResponseData));
      if (data) {
        let {screenName,title} = data;
         this.setState({ screenName , title }, () => {
          this.getSWTermsAndConditionData();
          window.scrollTo(0, 0);
         })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await this.getUserToken();
    const screenName = this.props.navigation.getParam("navigationBarTitleText");
    const title = pageTitles[screenName] || "Terms and Condition"
    this.setState({ screenName,title }, () =>{
        this.getSWTermsAndConditionData()
    })
  }

  getUserToken = async () => {
    const token = await getStorageData("loginToken");
    this.setState({ userToken: token });
    await this.getTermsAndConditionsList();
  };

  termsAndConditionsApiCall = (apiCallData: APIPayloadType) => {
    let { endPoint, body, method, contentType, type } = apiCallData;
    const header = {
      "Content-Type": contentType,
      token: this.state.userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? body : JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBacksTermsandCondition = (
    apiRequestCallId: string,
    responseJson:
      | TermsAndConditions
      | TermsAndConditionsListResponse
      | SWTermsAndConditionsResponse
      | ErrorResponse
  ) => {
    switch (apiRequestCallId) {
      case this.getTermsAndConditionsListApiCallId:
        this.handleGetTermsAndConditionsResponse(
          responseJson as TermsAndConditionsListResponse
        );
        break;
      case this.showTermsAndConditionsApiCallId:
        this.handleShowTermsAndConditionsResponse(
          responseJson as TermsAndConditions
        );
        break;
      case this.submitTermsAndConditionsApiCallId:
        this.handleUpdateTermsAndConditionsResponse(
          responseJson as ErrorResponse
        );
      case this.getSWTermsAndConditionsApiCallId:
        this.handleSWTermsAndConditionsResponse(
          responseJson as SWTermsAndConditionsResponse
        )
        break;
    }
  };

  handleUpdateTermsAndConditionsResponse = async (response: ErrorResponse) => {
    if (
      response.errors &&
      response.errors.length > 0 &&
      response.errors[0].token === configJSON.invalidTokenMessage
    ) {
      this.setState({
        isAlert: true,
        alertMsg: "Invalid token",
        alertType: configJSON.alertTypeError,
        loader: false
      });
    } else {
      const alertMsg = configJSON.refundPolicyUpdateSuccessMsg;
      const alertType: Color = configJSON.alertTypeSuccess;

      this.handleFieldReset();
      this.setState(
        {
          isAlert: true,
          alertMsg,
          alertType,
          loader: false
        },
        () => {
          this.navigationTermsAndConditions();
        }
      );
    }
  };
  handleSWTermsAndConditionsResponse = (response: SWTermsAndConditionsResponse) => {
    const {
        attributes: { description }
    } = response.data[0] || response.data;

    this.setState({
      loader: false,
      swTermsandCondition: description
    });
  };

  handleShowTermsAndConditionsResponse = (response: TermsAndConditions) => {
    const {
      data: {
        id,
        attributes: { description }
      }
    } = response;

    this.setState({
      loader: false,
      description: description,
      isUpdate: true,
      termsAndConditionsId: id
    });
  };

  handleGetTermsAndConditionsResponse = (
    response: TermsAndConditionsListResponse
  ) => {
    this.setState({
      termsAndConditions: response.data,
      loader: false
    });
  };

  handleDescriptionChange = (value: string) => {
    this.setState({
      description: value
    });
  };

  handleFieldReset = () => {
    this.setState({ description: "" });
  };

  onCloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getBlankField = (description: string) => {
    if (!description.trimStart()) {
      return configJSON.descriptionText;
    }
    return null;
  };

  handleUpdateTermsAndConditions = async () => {
    this.setState({ loader: true });
    const { description } = this.state;
    const blankField = this.getBlankField(description);

    if (blankField) {
      this.setState({
        isAlert: true,
        alertMsg: `${blankField}${configJSON.errorMsgBlankField}`,
        alertType: configJSON.alertTypeError,
        loader: false
      });
    } else {
      const body = {
        terms_and_condition: {
          description: this.state.description
        }
      };
      this.submitTermsAndConditionsApiCallId = this.termsAndConditionsApiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.examplePutAPiMethod,
        endPoint: `${configJSON.getTermsAndConditionsBasicApiEndpoint}${
          this.state.termsAndConditionsId
        }`,
        body: body
      });
    }
  };

  handleShowTermsAndConditions = async (
    termsAndConditionsId: string | number
  ) => {
    this.setState({ loader: true });
    const endPoint = `${
      configJSON.getTermsAndConditionsBasicApiEndpoint
    }${termsAndConditionsId}`;
    this.showTermsAndConditionsApiCallId = this.termsAndConditionsApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  getTermsAndConditionsList = async () => {
    this.setState({ loader: true });
    const endPoint = configJSON.getTermsAndConditionsApiEndpoint;
    this.getTermsAndConditionsListApiCallId = this.termsAndConditionsApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint
    });
  };

  getSWTermsAndConditionData = async () => {
    this.setState({ loader: true });
    let endPoint = configJSON.swTermsandConditionApiEndPoint;
    switch(this.state.screenName) {
      case "termsandcondition":
        endPoint = configJSON.swTermsandConditionApiEndPoint;
      break;
      case "refundpolicy":
        endPoint = configJSON.swRefundPolicyApiEndPoint;
      break;
      case "privacypolicy":
        endPoint = configJSON.swPrivacyPolicyApiEndPoint;
      break;
      case "howitworks":
        endPoint = configJSON.swHowItWorksApiEndPoint;
      break;
      case "aboutus":
        endPoint = configJSON.swAboutUsApiEndPoint;
      break;
      case "cancellationpolicy":
        endPoint = configJSON.swCancellationPolicyApiEndPoint;
      break;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSWTermsAndConditionsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  navigationEditTermsAndConditions = (
    termsAndConditionsId: string | number
  ) => {
    const editTermsAndConditionsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationAddTermAndConditionMessage)
    );
    editTermsAndConditionsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    editTermsAndConditionsScreenMsg.addData(
      getName(MessageEnum.NavigationIdMessage),
      {
        termsAndConditionsId: termsAndConditionsId
      }
    );
    this.send(editTermsAndConditionsScreenMsg);
  };

  navigationTermsAndConditions = () => {
    const termsAndConditionsScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    termsAndConditionsScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(termsAndConditionsScreenMsg);
  };
  parseErrors = async (responseJson: {
    errors: {
      token: string;
    }[];
  }, apiCallId: string) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    }else if(apiCallId === this.submitTermsAndConditionsApiCallId) {
      this.handleUpdateTermsAndConditionsResponse(responseJson as ErrorResponse);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  navigationLoginScreen = () => {
    alert(configJSON.sessionExpireMsg)
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };
  // Customizable Area End
}
