Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.baseURL = "https://mrsatyammoskul-243554-ruby.b243554.dev.eastus.az.svc.builder.cafe/";
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.stateApiEndPoint = `bx_block_profile/states/show_states/1`
exports.CityAPiEndPoint = `bx_block_profile/districts/show_districts/`

exports.MobileNumberEndPoint = "account_block/sent_otp_on_mobile";

//signup

exports.SignupWithMobileEndpoint = "account_block/sent_otp_on_mobile";
exports.signupWithMobileApiMethodType = "POST";

exports.SignupWithMobileVerifyEndpoint = "account_block/verify_mobile_otp";

exports.RegisterWithMobileVerifyEndpoint = "account_block/register";
exports.emailcheckRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

exports.EmailSignupSendOTPEndPoint = "account_block/sent_otp_on_mail";
exports.SignupWithEmailVerifyEndpoint = "account_block/verify_mail_otp";

exports.SignupWithMobileResendApiEndpoint = "account_block/resend_otp_on_mobile";
exports.SignupWithMobileResendVerifyApiEndpoint = "account_block/verify_mobile_otp";

exports.SignupWithEmailResendApiEndpoint = "account_block/resend_otp_on_email";
exports.SignupWithEmailResendVerifyApiEndpoint = "account_block/verify_mail_otp";

exports.profileInfoApiEndpoint = "bx_block_profile/profiles/user_profile";
exports.minutesText = "Minutes";
// Customizable Area End
