// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { Color } from "@material-ui/lab/Alert";
import { apiCall } from "../../user-profile-basic/src/FooterController.web";
import { LaunchedCourses } from "../../landingpage/src/LandingPage.web";
import { updateWishlistCount } from "../../wishlist/src/WishlistController.web";
import React from "react";
export const configJSON = require("./config");

interface ResponseErrors {
  errors: {
    token: string;
  }[];
}

export interface AddCartResponse {
  message: string;
}

export interface CourseDetailResponse {
  meta: Meta;
  data: CourseDetailData;
  message: string;
}

export interface RelatedCoursesResponse {
  data: CourseDetailData[];
}

export interface WishlistResponse {
  meta?: {
    message: string;
  };
  message?: string;
}

export interface CourseDetailData {
  id: string;
  type: string;
  attributes: CourseDetailsAttributes;
}

export interface CourseDetailsAttributes {
  course_name: string;
  short_description: string;
  full_description: string;
  account_id: number;
  first_year_price: number;
  second_year_price: number | null;
  discounted_price: number;
  preview_video_url: string;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  trending: boolean;
  what_learn: string[];
  course_sub_category: CourseSubCategory;
  course_child_category: CourseChildCategory;
  created_on: string;
  student_count: number;
  course_category: CourseCategory;
  instructor_name: string;
  instructor_details: InstructorDetails;
  language_name: string;
  total_content: number;
  average_rating: AverageRating;
  specfifc_content_duration: SpecfifcContentDuration[];
  course_thumbnail: string;
  all_course_class_duration: number;
  is_wishlist: string;
  is_purchased: boolean;
  is_cart: boolean;
  course_class_count: number;
}

export interface AverageRating {
  id: number;
  course_name: string;
  duration: null;
  year: null;
  created_at: Date;
  updated_at: Date;
  short_description: string;
  current_price: null;
  price: null;
  video_url: null;
  full_description: string;
  multiple_videos: null;
  course_category_id: number;
  valid_for_discount: null;
  course_sub_category_id: number;
  course_child_category_id: number;
  account_id: number;
  first_year_price: number;
  second_year_price: null;
  discounted_price: number;
  preview_video_url: string;
  what_learn: string[];
  status: boolean;
  trending: boolean;
  institute_id: number;
  language_options_language_id: number;
  Rating: { [key: string]: number };
  Average_rating: number;
  total_rating_count: number;
  total_review_count: number;
}

export interface CourseCategory {
  id: number;
  name: string;
}

export interface CourseChildCategory {
  id: number;
  course_category_id: number;
  course_sub_category_id: number;
  name: string;
  total_courses: null;
  total_students: null;
  status: boolean;
  color: string;
  created_at: Date;
  updated_at: Date;
}

export interface CourseSubCategory {
  id: number;
  name: string;
  course_category_id: number;
  created_at: Date;
  updated_at: Date;
  status: boolean;
  total_courses: null;
  total_subjects: null;
}

export interface InstructorDetails {
  name: string;
  details: string;
  qualifications: string;
  leacture_in: string;
  profile_icon: string;
}

export interface SpecfifcContentDuration {
  id: number;
  course_id: number;
  name: string;
  details: string;
  status: boolean;
  no_of_classes: null;
  created_at: Date;
  updated_at: Date;
  course_chapter_thumbnail: string;
  course_learning_material: string;
  class_count: number;
  duration_count: number;
  classes_data: ClassesDatum[];
}

export interface ClassesDatum {
  id: number;
  course_chapter_id: number;
  title: string;
  details: string;
  class_type: string;
  type_url: null;
  duration: string;
  status: boolean;
  featured: boolean;
  created_at: Date;
  updated_at: Date;
  video_thumbnail: string;
  video_upload: string;
}

export interface Meta {
  message: string;
}

const courseDetailTabs: unknown[] = [];

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string;
    navigate: (value: string) => void;
  };
  id: string;
  // Customizable Area End
}

export interface APIPayload {
  contentType?: string;
  method: string;
  endPoint: string;
  apiBody?: object;
  body?: object;
  token?: string;
  type?: string;
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isTrendingCoursesLoading: boolean;
  trendingCourses: LaunchedCourses[];
  pageNo: number;
  listMaxPerPage: number;
  lastPage: number | undefined;
  courseIndex: number;
  isCourseValidityOpen: boolean;
  courseValidityDetails: Record<string, any>;
  selectedValidity: number;
  userToken: string;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  courseDetail: CourseDetailsAttributes | undefined;
  currentTab: number;
  tabs: unknown[];
  relatedCourses: CourseDetailData[];
  courseId: string;
  courseType: "course" | "bundle_course";
  openedAccordions: number[];
  isCourseVideoPlay: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseDetailApiCallId: string = "";
  getRelatedCoursesApiCallId: string = "";
  getTrendingCoursesApiCallId: string = "";
  toggleWishlistApiCallId: string = "";
  addToChartApiCallId: string = "";

  protected stepInput: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationIdMessage)
    ];

    this.stepInput = React.createRef();

    this.state = {
      alertMsg: "",
      alertType: "success",
      isAlert: false,
      isLoading: true,
      userToken: "",
      courseDetail: undefined,
      relatedCourses: [],
      currentTab: 0,
      tabs: courseDetailTabs,
      courseId: "0",
      courseType: "course",
      openedAccordions: [],
      isCourseVideoPlay: false,
      pageNo: 1,
      listMaxPerPage: 12,
      lastPage: undefined,
      isTrendingCoursesLoading: false,
      trendingCourses: [],
      courseIndex: 0,
      isCourseValidityOpen: false,
      courseValidityDetails: {},
      selectedValidity: 1
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    await this.handleUserToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getRelatedCoursesApiCallId:
            this.handleGetRelatedCoursesResponse(responseJson);
            break;
          case this.getCourseDetailApiCallId:
            this.handleGetDetailsResponse(responseJson);
            break;
          case this.getTrendingCoursesApiCallId:
            this.handleTrendingCourses(responseJson);
            break;
          case this.toggleWishlistApiCallId:
            this.handleWishlistStatusResponse(responseJson);
            break;
          case this.addToChartApiCallId:
            this.handleChartStatusResponse(responseJson);
            break;
        }
      } else {
        this.parseErrors(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async handleUserToken() {
    const token = await getStorageData("loginToken");
    const courseId = this.props.navigation.getParam("courseId");
    this.setState({ userToken: token, courseId: courseId });
    this.getCourseDetail(courseId);
    this.getTrendingCourses(this.state.pageNo, this.state.listMaxPerPage);
  }

  parseErrors = async (responseJson: ResponseErrors) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      this.navigationLoginScreen();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  navigationLoginScreen = () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageLogin);
  };

  navigationHomeScreen = () => {
    const messageHome: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    messageHome.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(messageHome);
  };

  handleViewCourseList = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Categoriessubcategories");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), { screenName: "Trending Courses" });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  navigationShoppingCart = () => {
    const shoppingCartScreenMsg: Message = new Message(
      getName(MessageEnum.NavigationShoppingCartMessage)
    );
    shoppingCartScreenMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(shoppingCartScreenMsg);
  };

  handleTrendingCoursePage = (direction: "next" | "prev") => {
    const currentPage = this.state.pageNo + (direction === "next" ? 1 : -1);
    this.setState({ pageNo: currentPage }, () => {
      const totalPages = Math.ceil(this.state.trendingCourses.length / 4);
      const isLastPage = currentPage > totalPages;
      const totalPagesApi = Math.ceil(
        (isLastPage ? totalPages + 1 : totalPages) / 3
      );
      if (isLastPage) {
        this.getTrendingCourses(totalPagesApi, this.state.listMaxPerPage);
      }
    });
  };

  disabledNextButtons() {
    return (
      this.state.pageNo === this.state.lastPage ||
      this.state.isTrendingCoursesLoading
    );
  }

  disabledBackButtons() {
    return this.state.pageNo === 1 || this.state.isTrendingCoursesLoading;
  }

  handleOpenCourseValidityPopup = (
    isAdd: boolean,
    course: any,
    list: "newLaunchCourses" | "trendingCourses" | "bundleCourses",
    index: number
  ) => {
    if (!this.state.userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your cart.",
        alertType: "info",
        isAlert: true
      });
      return;
    }
    if (isAdd) {
      this.setState({
        alertMsg: "Redirect to cart",
        isAlert: true,
        alertType: "info"
      });
      this.navigationShoppingCart();
      return;
    }
    this.setState(
      {
        courseIndex: index,
        courseValidityDetails: course
      },
      () => {
        this.setState({ isCourseValidityOpen: true });
      }
    );
  };

  async handleWishlistStatusResponse(response: WishlistResponse) {
    if(response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
      await updateWishlistCount(false);
    }
    if (response && response.meta) {
      this.setState({
        alertMsg: response.meta.message,
        isAlert: true,
        alertType: "success"
      });
      await updateWishlistCount(true);
    }
  }

  handleWishlistStatus(isAdd: boolean, courseId: string) {
    if (!this.state.userToken) {
      this.setState({
        alertMsg: "Please log in to add courses to your wishlist.",
        alertType: "info",
        isAlert: true
      });
      return;
    }

    const headers = {
      "Content-Type": !isAdd ? configJSON.apiContentType : undefined,
      token: this.state.userToken
    };

    const body = {
      wishlist: {
        course_id: courseId
      }
    };
    const formdata = new FormData();
    formdata.append("course_id", courseId);

    let clone = [...this.state.trendingCourses];
    const index = clone.findIndex(e => e.id === courseId);
    clone[index].attributes.is_wishlist = `${!isAdd}`;

    const stateToSet: unknown = { trendingCourses: clone };

    this.setState(stateToSet as Pick<S, keyof S>);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.toggleWishlistApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      !isAdd
        ? configJSON.wishlistApiEndpoint
        : configJSON.removeWishlistApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      !isAdd ? JSON.stringify(body) : formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      !isAdd ? configJSON.postMethod : configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async copyAndNavigate(url: string) {
    await this.copyTextToClipboard(window.location.href);
    window.open(url, "_blank");
  }

  async copyTextToClipboard(url: string) {
    try {
      const textToCopy = "URL copied to clipboard.";
      await navigator.clipboard.writeText(url || "");
      this.setState({
        isAlert: true,
        alertMsg: textToCopy,
        alertType: "success"
      });
    } catch (error) {
      this.setState({
        isAlert: true,
        alertMsg: "Can't copy code to clipboard",
        alertType: "error"
      });
    }
  }

  getLastPageIndex(arrayLength: number, elementsPerPage = 4) {
    const totalPages = Math.ceil(arrayLength / elementsPerPage);
    return totalPages;
  }

  handleAccordionChange(index: number) {
    const array = [...this.state.openedAccordions],
      findAccordion = array.findIndex(v => index === v);
    if (findAccordion > -1) {
      this.setState({
        openedAccordions: [
          ...this.state.openedAccordions.filter(v => v !== index)
        ]
      });
    } else {
      this.setState({
        openedAccordions: [...this.state.openedAccordions, index]
      });
    }
  }

  handleExpandAllAccordions(indexArray: number[]) {
    this.setState({
      openedAccordions:
        this.state.openedAccordions.length === indexArray.length
          ? []
          : indexArray
    });
  }

  handleTrendingCourses(responseJson: any) {
    if (!responseJson.data) {
      this.setState({
        pageNo: this.state.pageNo - 1,
        isTrendingCoursesLoading: false,
        lastPage: this.state.pageNo
      });
    } else {
      const arrayLength = responseJson.data.length;
      this.setState({
        trendingCourses: [...this.state.trendingCourses, ...responseJson.data],
        isTrendingCoursesLoading: false,
        lastPage:
          arrayLength < 12
            ? this.state.pageNo + this.getLastPageIndex(arrayLength)
            : undefined
      });
    }
  }

  handleCartStatusResponse(response: AddCartResponse) {
    if (response && response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success"
      });
    }
  }

  getTrendingCourses(pageNo: number, perPage: number) {
    this.setState({ isTrendingCoursesLoading: true });
    this.getTrendingCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getTrendingCoursesWebApiEndPoint +
        `/?page=${pageNo}&per_page=${perPage}`,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  getCourseDetail(courseId: string) {
    this.setState({ isLoading: true });
    this.getCourseDetailApiCallId = apiCall({
      endPoint: configJSON.getCourseDetailApiEndpoint + courseId,
      method: configJSON.getMethod,
      ...(this.state.userToken ? { token: this.state.userToken } : {})
    });
  }

  getRelatedCourse() {
    this.setState({ isLoading: true });
    this.getRelatedCoursesApiCallId = apiCall({
      endPoint:
        configJSON.getRelatedCoursesApiEndpoint +
        `?course_id=${this.state.courseId}&course_child_category_id=${
          this.state.courseDetail!.course_child_category.id
        }`,
      method: configJSON.getMethod,
      token: this.state.userToken
    });
  }

  handleGetRelatedCoursesResponse(responseJson: RelatedCoursesResponse) {
    if (responseJson && responseJson.data) {
      this.setState({
        relatedCourses: responseJson.data,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleChartStatusResponse(response: AddCartResponse) {
    if (response && response.message) {
      this.setState({
        alertMsg: response.message,
        isAlert: true,
        alertType: "success",
        isCourseValidityOpen: false
      });
      this.state.courseValidityDetails.hasOwnProperty("redirect") && this.navigationShoppingCart();
    }
  }

  handleChartStatus(
    isCard: boolean,
    courseId: string,
    type: string,
    no_of_year: number = 1
  ) {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.userToken
    };
    const body = {
      course_id: courseId,
      no_of_year,
      text: type
    };

    if (isCard) {
      let clone = [...this.state.trendingCourses];
      const index = clone.findIndex(e => e.id === courseId);
      clone[index].attributes.is_cart = true;

      const stateToSet: unknown = { trendingCourses: clone };

      this.setState(stateToSet as Pick<S, keyof S>);
    } else {
      this.setState({
        courseDetail: { ...this.state.courseDetail!, is_cart: true }
      });
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToChartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToChartApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  oncloseAlert() {
    this.setState({
      isAlert: false
    });
  }

  handleGetDetailsResponse(responseJson: CourseDetailResponse) {
    if (responseJson && responseJson.message) {
      this.navigationHomeScreen();
    } else if (responseJson && responseJson.data) {
      this.setState(
        {
          courseDetail: responseJson.data.attributes,
          courseType: responseJson.data.type as "course",
          isLoading: false
        },
        () => {
          this.getRelatedCourse()
          if(responseJson.data.attributes?.specfifc_content_duration[0]?.id) {
           this.handleAccordionChange(responseJson.data.attributes.specfifc_content_duration[0].id)
          }
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  convertToTimeFormat(totalSeconds: number) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  convertToHoursAndMinutes(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    
    return `${hours.toString().padStart(2, '0')} hours, ${minutes.toString().padStart(2, '0')} minutes`;
  }

  onClose = () => {
    this.setState({ selectedValidity: 1, isCourseValidityOpen: false });
  };

  handleSelectValidity = (validity: 1 | 2) => {
    this.setState({ selectedValidity: validity });
  };

  handleCourseVideoPlay = () => {
    this.setState({ 
      isCourseVideoPlay: !this.state.isCourseVideoPlay 
    })
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
