Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getTrendingCoursesWebApiEndPoint="bx_block_profile/courses/trending_courses"
exports.bundleCoursesWebApiEndPoint="bx_block_profile/bundle_courses"
exports.getNewLaunchedCourseWebApiEndPoint="bx_block_profile/courses/new_lunched_courses"
exports.btnExampleTitle = "CLICK ME";
exports.logoutApiEndpoint = "bx_block_login/login/account_logout";
exports.getOurAchievementWebApiEndPoint = "bx_block_profile/achievements/show_achievement";
exports.getOurInstructionWebApiEndPoint = "bx_block_profile/courses/landing_page_instructors";
exports.watchDemoVideoYoutubeLink = "https://www.youtube.com/watch?v=n434ha4QwU0";
exports.exploreBtnText = "Explore Now";
exports.viewDemoText = "View Demo";
exports.watchOurText = "Watch Our";
exports.demoCourse = "Demo Course";
exports.addToChartApiEndpoint = "bx_block_shopping_cart/add_to_cart";
exports.wishlistApiEndpoint = "bx_block_wishlist/add_wishlist";
exports.removeWishlistApiEndpoint = "bx_block_wishlist/remove_course_from_wishlist";
exports.watchFreeDemo = "Watched free demo";
exports.studentReviewText = "Student Reviews";
exports.studentText = "STUDENT";
exports.getStudentReviewApiEndPoint = "bx_block_profile/reviews/landing_page_review";
exports.getWebTopBannerApiEndPoint = "bx_block_profile/banners";
exports.getFooterInfoApiEndPoint = "bx_block_profile/footers/show_footer";
exports.getEducationCategoryApiEndPoint = "/bx_block_course/course_categories";
exports.getEducationSubCategoryApiEndPoint = "/bx_block_course/course_sub_category/get_sub_category_based_category";
exports.SubCategoryAPiEndPoint = "bx_block_course/get_child_category_course";
exports.courseValidityTitle = "Choose your Course Validity";
exports.educationStudentTitle = "Affordable Education for Every Student";
exports.educationStudentSubtitle = "We believe every student deserves quality education. CHSE Express is Odisha's No.1 online learning platform, we offer comprehensive courses at an affordable price, empowering students from all backgrounds to succeed in their exams and beyond. Join us today and unlock your potential with CHSE Express: Your Learning Partner";
exports.ourAchievementTitle = "Our Achievements";
exports.ourInstructorTitle = "Our Instructors";
exports.ourAchievementSubTitle = "Discover our milestones and successes that make CHSE Express Odisha's top online learning platform.";
exports.howItWorksSignupTitle = "SIGN UP";
exports.howItWorkSignupDesc = "Register for free in just a few simple steps";
exports.howItWorksSelectCoursesTitle = "SELECT COURSE"
exports.howItWorksSelectCoursesDesc = "Choose from a wide range of courses that suit your needs.";
exports.howItWorksLearningTitle = "SELECT LEARNING";
exports.howItWorksLearningDesc = "Access your course online or download for offline learning";
exports.totalFollowerText = "TOTAL FOLLOWERS";
exports.certifiedTeacherText = "CERTIFIED TEACHERS";
exports.onlineCoursesText = "ONLINE COURSES";
exports.studentEnrollText = "STUDENTS ENROLLED"
exports.validity1Year = "1 Year Validity";
exports.validity2Year = "2 Year Validity";
exports.viewAllText = "VIEW ALL";
exports.emptySubCategoriesMsg = "No Sub categories added yet."
exports.emptyChildCategoriesMsg = "No Child categories added yet";
// Customizable Area End