import React, { CSSProperties } from "react";
import {
    Button,
    Typography,
    CardContent,
    Avatar,
    styled,
    Grid,
    Box,
    Tabs, 
    Tab, 
    Chip, 
    Dialog, 
    DialogTitle, 
    DialogContent,  
    DialogActions,
    responsiveFontSizes
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { Rating } from '@material-ui/lab';
import Slider,{CustomArrowProps} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdCurrencyRupee } from "react-icons/md";
import { FaHeart, FaCircleCheck } from 'react-icons/fa6';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {studentArrowGroup,reviewIdeaLamp,orangePlanetWithDisk, addFriend1, addFriend2, addFriend3, forwardArrow, backArrowPage, chapter, courses, enrolled, favFolder, followers, forwardArrowPage, invertedCommaEnd, invertedCommaStart, lecture, playIcon, star, study, teachers, time, laptopLastImg,  book, verticalLine, dashedLine, coursesIcon ,web_BG_Visibility} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import LandingPageController, { BannerListData, Props, configJSON, EducationCategoryAttr } from "./LandingPageController";
import "./LandingPage.css"

import YouTubeIcon from '@material-ui/icons/YouTube';
import { Close as CloseIcon ,Check as CheckIcon} from '@material-ui/icons';
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import { LiaRupeeSignSolid } from "react-icons/lia";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

export enum CardType {
    newLaunchCourses = "newLaunchCourses",
    trendingCourses = "trendingCourses",
    bundleCourses = "bundleCourses"
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        style={{ background: "#F3F3F3",minHeight: 300, borderRadius: "12px",margin: "0px 6%", boxShadow: '0px 1px 10px #EBEBEB', outline: '4px solid #F0F0F0' }}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const InstructorSliderBox = styled(Box)(() => ({
    maxWidth: 450, 
    margin: 'auto',
    marginBottom: 12,
    '@media (min-width: 1080px) and (max-width: 1330px)': {
       maxWidth: 650, 
    }
  }))

  const OurAchievementTitleText = styled(Typography)(() => ({
    color: "#FFF",
    fontFamily: 'Inter',
    fontSize: " 20.633px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.025px",
    fontWeight: 400,
    [theme.breakpoints.down('lg')]:{
        fontSize: 18
    },
    '@media (min-width: 1280px) and (max-width: 1366px)': {
        fontSize: 16
      },
      '@media (min-width: 900px) and (max-width: 1280px)': {
        fontSize: 15
      },

  }));

  const LaunchTrendCard = styled(Box)(() => ({
    '& .slider-content': {
        transition: 'transform 0.5s ease-in-out',
        willChange: 'transform'
      },
      '& .slide-right': {
        transform: 'translateX(100%)',
        overflow: "hidden"
      },
      '& .slide-left': {
        transform: 'translateX(-100%)',
        overflow: "hidden"
      }
  }));
  const TrendCourseCard = styled(Box)(() => ({
    '& .slider-content': {
        transition: 'transform 0.5s ease-in-out',
        willChange: 'transform'
      },
      '& .slide-right': {
        transform: 'translateX(100%)',
        overflow: "hidden"
      },
      '& .slide-left': {
        transform: 'translateX(-100%)',
        overflow: "hidden"
      }
  }));
  
export const CustomDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: 550,
        minWidth: 500,
        fontFamily: 'Inter'
      },
    '.dialogContainer': {
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 550,
          minWidth: 500
        }
      },
    
      '& .dialogTitleRoot': {
        padding: theme.spacing(2),
        position: 'relative' as const,
        borderBottom: '2px solid #000'
      },
      '& .closeButton': {
        position: 'absolute' as const,
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#000',
      },
    
      '& .dialogTitle': {
        textAlign: 'start' as const,
        fontWeight: 600,
        fontSize: 28.068172454833984,
        letterSpacing: -0.3255172073841095,
        color: '#000000ff',
        fontFamily: 'Inter'
      },
      '& .dialogContent': {
        textAlign: 'start' as const,
        fontFamily: 'Inter'
      },
      '& .subTitleCourseValidity': {
        fontWeight: 400,
        fontSize: 25,
        letterSpacing: -0.2899344563484192,
        color: '#000000ff',
        fontFamily: 'Inter',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word' as const,
        WebkitLineClamp: 3,
        display: "-webkit-inline-box",
        WebkitBoxOrient: "vertical" as const,
        textTransform: "capitalize" as const,
        hyphens: 'auto' as const
      },
      '& .buttonLabel': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative' as const
      },
      '& .checkIcon': {
        marginRight: theme.spacing(1),
        background: '#fff',
        color: '#366A76',
        borderRadius: '50%',
        fontSize: '16px',
        position: 'absolute' as const,
        left: 0
      },
      '& .buttonGroup': {
        display: 'flex',
        flexDirection: 'column' as const,
        marginTop: theme.spacing(2),
        '& > *': {
          marginBottom: theme.spacing(1),
        },
      },
      '& .button': {
        borderColor: '#4C8491',
        color: "#4C8491",
        borderRadius: '25px',
        padding: theme.spacing(1, 3),
        fontSize: 18,
        fontFamily: 'Inter',
        '&.selected': {
          backgroundColor: "#4C8491",
          color: '#fff',
        },
      },
      '& .cancelButton': {
        color: '#000',
        width: '50%',
        fontFamily: 'Inter',
        background: "#EDEDED",
        fontSize: '18px',
        fontWeight: 400,
        borderRadius: 8,
        '&:hover': {
          color: '#000',
          backgroundColor: "#EDEDED"
        }
      },
      '& .applyButton': {
        width: '50%',
        color: '#fff',
        backgroundColor: "#4C8491",
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 400,
        borderRadius: 8,
        '&:hover': {
          color: '#fff',
          backgroundColor: "#4C8491"
        }
      }
  }))
const EducationalCategoryListWrapper = styled(Box)(() => (
    {
        minHeight: '300px',
        '& .roots': {
          flexGrow: 1,
        },
        "& .MuiTab-root":{
            overflow: "visible"
        },
        '& .tabs': {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          overflowY: 'visible',
          padding: "0px 32px",
          '@media (min-width: 1444px) and (max-width: 1920px)':{
            padding: "0px 58px"
          },
          "& .MuiTab-root":{
            overflow: "visible"
        },
          '& .MuiTabs-flexContainer': {
              gap: 8,
          },
          '& .MuiTabs-indicator': {
            display: "none"
          },
          "& .Mui-selected": {
            background: "linear-gradient(140.89deg, #308CA1 16.03%, #174B57 96.78%)"
          }
        },
        '& .MuiTabs-scrollable' :{
            marginBottom: 0,
            height: '80px'
        },
        '& .MuiTabs-scrollButtonsDesktop': {
            height: '48px'
        },
        '& .tab': {
            cursor: 'pointer',
            width: '16.1%',
            background: "#A8DAE5",
            borderBottomRightRadius: 16,
            borderBottomLeftRadius: 16,
            fontFamily: 'Inter',
              fontWeight: '400',
              fontSize: 22.633052825927734,
              letterSpacing: 0.0943043902516365,
              color: '#000',
            position: 'relative',
            overflowY: 'visible',
            '&.Mui-selected': {
                overflowY: 'visible',
                color: '#fff',
                '&::after': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  left: '50%',
                  bottom: -25,
                  transform: 'translateX(-50%)',
                  width: 5,
                  height: 0,
                  borderLeft: '15px solid transparent',
                  borderRight: '15px solid transparent',
                  borderBottom: `15px solid rgb(243, 243, 243)`,
                },
              },
              [theme.breakpoints.down('sm')]: {
                width: '25%',
                fontSize: 18,
              },
              [theme.breakpoints.down('xs')]: {
                width: '50%',
                fontSize: 16,
              },
        },
        '& .chipContainer': {
          display: 'flex',
          justifyContent: 'start',
          marginBottom: theme.spacing(4),
          overflowX: 'auto',
          width: '100%'
        },
        '& .chip': {
            cursor: 'pointer',
            margin: theme.spacing(1),
            padding: '0px 20px',
            backgroundColor: '#fff', 
            borderRadius: 25, 
            height: 50,
            fontFamily: 'Inter',
            fontWeight: '600',
            fontSize: 19.61151123046875,
            letterSpacing: 0.08171463757753372,
            color: '#BD8DC5',
            borderColor: "#BD8DC5",
            border: '1px solid #BD8DC5',
            minWidth: 180,
            [theme.breakpoints.down('sm')]: {
                width: 150,
                height: 40,
                fontSize: 17,
              },
              [theme.breakpoints.down('xs')]: {
                width: 130,
                height: 35,
                fontSize: 15,
              },
          
        },
        '& .cards': {
            cursor: 'pointer',
          color: theme.palette.text.secondary,
          margin: 'auto',
          '& .cardMain':{
            padding: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: 'center',
          }
        },
        '& .education-subjectname':{
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: 19.61151123046875, 
                textAlign: "center", 
                whiteSpace: 'initial', 
                marginTop: 20,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                overflowWrap: 'break-word',
                hyphens: 'auto'
        },
        '& .icon': {
          fontSize: 40,
          marginBottom: theme.spacing(1),
        },
        '& .media': {
          height: 140,
        },
        '& .subjectContainer': {
            display: 'flex',
            alignItems: 'center',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            justifyContent: 'flex-start',
            padding: theme.spacing(1),
          },
          '& .subjectItem': {
            display: 'inline-flex',
            alignItems: 'center',
            padding: theme.spacing(1),
          },
        '& .divider': {
            marginRight: 30,
          },
          '& .scrollbar': {
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#578D92',
              borderRadius: '4px',
            },
          },
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#578D92',
            borderRadius: '4px',
          },
          '& .activeChip': {
            background: 'linear-gradient(133deg, rgb(249, 58, 58) -26.6%, rgb(168, 31, 225) 118.85%) !important',
            color: '#fff',
          },
      }));
const StudentWrapper = styled(Box)(() => ({
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        height: '100%',
        maxHeight: '500px',
        [theme.breakpoints.down('md')]: {
          height: '500px',
          maxHeight: 'none',
          minHeight: '500px',
        },
        '& .custom-slider-class': {
            display: 'flex',
            alignItems: 'center',
            margin: '12px 50px',
            paddingTop: 50,
            '@media (max-width: 1280px)':{
                margin: '12px',
                '& .studentReview': {
                    minWidth: 250
                }
            },
            '@media (min-width: 1280px) and (max-width: 1366px)': {
                '& .studentReview': {
                    minWidth: 290
                }
            }
          }
}))
const CartButton = styled(Button)(() => ({
    padding: "10px 16px",
    borderRadius: "10px",
    minHeight: "40px",
    height: '40px',
    "& .MuiButton-startIcon": {
        marginLeft: 0,
        marginRight: 4
    }
}));
const DemoBox = styled(Box)(() => ({
    height: 500, 
    padding: "12px 50px", 
    '& .buttons': {
        display: 'flex',
        gap: 12
    },
    '& .button': {
        borderRadius: 24,
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 18.9288272857666,
        letterSpacing: 0,
        color: '#ffffffff',
        background: "linear-gradient(133deg, rgb(249, 58, 58) -26.6%, rgb(168, 31, 225) 118.85%)",
        padding: "8px 24px"
    },
    '& .watchOurText': {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 39,
        letterSpacing: -0.04858291149139404,
        color: '#212121ff'
    },
    '& .demoCourseText': {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: 56,
        letterSpacing: -0.0695330947637558,
        color: '#212121ff'
    },
    '& .watchDemoText': {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: 0,
        color: '#212121ff',
        marginTop: 16
    },
    '& .icon': {
        fontSize: 50,
    },
    '& .imageContainer': {
        position: 'relative' as const,
        width: '100%',
        borderRadius: 12,
        cursor: 'pointer',
        overflow: 'hidden',
    },
    "& .image": {
        position: 'absolute' as const,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    '& .playIcon': {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: 60,
        color: 'red',
    },
    [theme.breakpoints.down('lg')]: {
        padding: "12px 30px",
        '& .watchOurText': {
          fontSize: 36,
        },
        '& .demoCourseText': {
          fontSize: 53,
        },
        '& .watchDemoText': {
          fontSize: 22,
        },
        '& .button': {
          fontSize: 17,
        },
      },
      [theme.breakpoints.down('md')]: {
        padding: "12px 20px",
        '& .watchOurText': {
          fontSize: 34,
        },
        '& .demoCourseText': {
          fontSize: 51,
        },
        '& .watchDemoText': {
          fontSize: 21,
        },
        '& .button': {
          fontSize: 16,
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: "12px 10px",
        marginTop: "50px",
        '& .watchOurText': {
          fontSize: 30,
        },
        '& .demoCourseText': {
          fontSize: 48,
        },
        '& .watchDemoText': {
          fontSize: 20,
        },
        '& .button': {
          fontSize: 15,
          padding: "6px 12px",
        },
      },
      [theme.breakpoints.down('xs')]: {
        padding: "12px 5px",
        marginTop: "25px",
        '& .watchOurText': {
          fontSize: 24,
        },
        '& .demoCourseText': {
          fontSize: 36,
        },
        '& .watchDemoText': {
          fontSize: 18,
        },
        '& .button': {
          fontSize: 14,
          padding: "4px 10px",
        },
      },
}))
const StudentReviewSlider = styled(Slider)(() => ({
    '& .slick-slide':{
        display: 'flex !important',
        justifyContent: 'space-evenly'
    },
    '& .slick-list': {
        width: "100% !important",
    },
    '& .reviewStudentImg': {
        '& .MuiAvatar-img' :{
            width: "92px",
            height: "92px",
            objectFit: 'fill',
            borderRadius: '50%',
        },
    },
    '& .studentReview': {
        border: "0.5px solid rgba(0, 0, 0, 0.18)",
        width : '300px',
        borderRadius: "18.72px",
        background: "#FFF",
        boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.18)",
        display: "flex",
        columnGap: "15px",
        padding: "20px",
        margin: '0px 20px',
        gap: 20,
        minHeight: '150px',
        flexDirection: 'row',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            textAlign: 'center',
            padding: '10px',
        },
    },
    '& .studentReviewTop': {
        display: "flex",
        alignItems: "center",
        columnGap: "5px",
        marginTop: 12,
        height: 28,
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            columnGap: "0px",
        },
    },
    '& .studentName': {
        color: "#212121",
        fontSize: "14.724px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "75.826px",
        letterSpacing: "-0.028px",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '@media (max-width: 768px)': {
            fontSize: "12px",
            lineHeight: "normal",
        },
    },
    '& .courseName': {
        border: "1px solid white",
        background: "white",
        color: "black",
        width: "max-content",
        borderRadius: "26.85px",
        padding: "5px",
        height: "min-content",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 16,
        '@media (max-width: 1336px)': {
            fontSize: 14
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    '& .studentReviewText': {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: "15px",
        fontStyle: "normal",
        lineHeight: "normal",
        marginTop: "8px",
        fontWeight: 400,
        letterSpacing: "-0.018px",
        display: '-webkit-box',
        textWrap: 'balance',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: "break-word",
        '@media (max-width: 1280px)': {
            fontSize: "13px",
        },
        '@media (max-width: 768px)': {
            fontSize: "12px",
            marginTop: "5px",
        },
    },
}));

const WebTopBannerSlider = styled(Slider)({
  height: '500px',
  '@media (min-width: 1664px) and (max-width: 1920px)': {
        height: 700
      },
    '@media (min-width: 600px) and (max-width: 1200px)':{
        height: 400
    },
  borderBottomRightRadius: "95px",
'& .slick-list': {
    minHeight: '220px',
    height: '500px',
    borderBottomRightRadius: "95px",
    '@media (min-width: 1664px) and (max-width: 1920px)': {
        height: 700
      },
},
  '& .slick-dots': {
    position: 'absolute' as const,
    bottom: '0',
    right: '-42%',
    transform: 'translateY(-50%)',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    '& .li':{
        margin: 0
    }
  },
  '& .slick-dots li button': {
    fontSize: 0,
    lineHeight: 0,
    display: 'block',
    width: '12px',
    height: '12px',
    padding: '5px',
    cursor: 'pointer',
    color: 'transparent',
    border: '2px solid #fff',
    borderRadius: '50%',
    backgroundColor: '#E4DBD9',
    outline: 'none',
  }, 
  '& .slick-dots li.slick-active button': {
    backgroundColor: '#F5BF0C',
    border: '2px solid #F5BF0C',
    width: '24px',
    height: '24px',
    margin: '0px -10px'
  },
  '& .slick-dots li button::before':{
    color : "transparent",
  },
  '& .slick-dots li.slick-active button::before': {
    color : "transparent"
  },
  '& .slick-slide img': {
    objectFit: "fill"
  }
})
const CustomSlider = styled(Slider)(() => ({
    display: 'flex',
    alignItems: "center",
    height: '100px',
    margin: 'auto',
    '& .slick-list': {
      width: "100% !important",
      marginTop: 50,
      height: '145px'
    },
    '& .slick-arrow' : {
      background: "#000"
    },
    '& .slick-slide': {
      paddingRight: '8px', 
      boxSizing: 'border-box',
    },
  
    '& .slick-track .slick-slide .MuiAvatar-circular': {
      width: "65px",
      marginTop: "10px", 
      height: "65px",
      '& img': {
        objectFit: 'fill'
      }
    },
    '& .slick-active':{
        width: 65,
        height: 65,
        maxWidth: 70
    },
    '& .slick-active .slick-center': {
        width: "92px !important",
        height: "92px !important",
    },
    '& .slick-current': {
      width: "92px !important",
      height: "92px !important",
      margin: "0px 8px",
      marginRight: 16,
      maxWidth: '105px !important'
    },
    '& .slick-center .MuiAvatar-circular':{
      width: "92px !important", 
      height: "92px !important",
      marginTop: "0px !important",
      '& img': {
        objectFit: 'fill'
      }
    },
    '& .slick-slide div div':{
      display: 'flex',
      justifyContent: 'center'
    }
  }));

interface StudentReviewData {
    id: string;
    type: string;
    attributes: {
        id: string;
        reviewable_id: string;
        rating: number;
        content: string
        status: boolean;
        visible_on_landing: boolean;
        user_name: {
            id: number
            full_phone_number: string;
            email: string;
            full_name: string
        },
        user_image: string;
      }
}

export interface LaunchedCourses {
    id:         string;
    type:       string;
    attributes: LaunchedCoursesAttributes;
}

export interface BundleCourses {
    id:         string;
    type:       string;
    attributes: BundleCoursesAttributes;
}

export interface BundleCoursesAttributes {
    short_description?: string;
    course_name?:string;
    bundle_name:             string;
    description:             string;
    overview:                string;
    tag:                     string;
    first_year_price:        number;
    second_year_price:       number | null;
    status:                  boolean;
    discounted_price:        number | null;
    rating:                  number;
    what_will_learn:         string[];
    course_category:         CourseCategory;
    total_student:           number;
    rating_data:             RatingData;
    course_count:            number;
    created_on:              string;
    add_course:              AddCourse[];
    bundle_course_thumbnail: string;
    is_wishlist:             string;
    is_purchased:            boolean;
    is_cart:                 boolean;
}

export interface AddCourse {
    id:                           number;
    course_thumbnail:             string;
}

export interface CourseCategory {
    id:   number;
    name: string;
}

export interface RatingData {
    total_rating_count: number;
    avrage_rating:      number;
}


export interface LaunchedCoursesAttributes {
    course_name:               string;
    short_description:         string;
    full_description:          string;
    account_id:                number;
    first_year_price:          number;
    second_year_price:         number;
    discounted_price:          number | null;
    preview_video_url:         string;
    status:                    boolean;
    trending:                  boolean;
    what_learn:                string[];
    course_sub_category:       CourseSubCategory;
    course_child_category:     CourseChildCategory;
    student_count:             number;
    course_category:           CourseCategory;
    instructor_name:           string;
    instructor_details:        InstructorDetails;
    language_name:             string;
    total_content:             number;
    average_rating:            AverageRating;
    specfifc_content_duration: null | {
        class_count: string | number;
    };
    course_thumbnail:          string;
    all_course_class_duration: number;
    is_wishlist:               string;
    is_purchased:              boolean;
    is_cart:                   boolean;
    course_class_count: null | number;
    description?: string;
    bundle_name?:string;
}

export interface AverageRating {
    id:                           number;
    Average_rating:               number;
}

export interface CourseCategory {
    id:   number;
    name: string;
}

export interface CourseChildCategory {
    id:                     number;
    course_category_id:     number;
    course_sub_category_id: number;
    name:                   string;
    total_courses:          null;
    total_students:         null;
    status:                 boolean;
    color:                  string;
    created_at:             Date;
    updated_at:             Date;
}

export interface CourseSubCategory {
    id:                 number;
    name:               string;
    course_category_id: number;
    created_at:         Date;
    updated_at:         Date;
    status:             boolean;
    total_courses:      null;
    total_subjects:     null;
}

export interface InstructorDetails {
    name:           string;
    details:        string;
    qualifications: string;
    leacture_in:    string;
    profile_icon:   string;
}

interface InstructorAttributes {
    full_name: string;
    details: string;
    profile_icon: string;
    leacture_in: string;
  }
  
  interface Instructor {
    id: string;
    type: string;
    attributes: InstructorAttributes;
  }
let theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },  
    typography: {
        allVariants: {
            fontFamily: "'Inter', monospace",
        },
        body1: {
            fontFamily: "'Inter', monospace",
        },
    },
});
theme = responsiveFontSizes(theme);

export default class LandingPage extends LandingPageController {

    constructor(props: Props) {
        super(props);
        this.closemodal = this.closemodal.bind(this);
        this.disabledBackButtons = this.disabledBackButtons.bind(this);
        this.disabledNextButtons = this.disabledNextButtons.bind(this);
        this.handleWishlistStatus = this.handleWishlistStatus.bind(this);
    }

    closemodal() {
        this.oncloseAlert();
    }

    showSkeleton(amount = 4) {
        return <>
            {Array(amount).fill(null).map(() => <Skeleton variant="rect" className="card" height={378} />)}
        </>;
    }

    showCoursePrice(value: LaunchedCoursesAttributes | BundleCoursesAttributes) {
        return <div
            style={{
                display: "flex",
                flexDirection: "column",
                columnGap: "10px",
            }}>
            <Typography style={{
                display: "inline-flex",
                columnGap: "10px",
                alignItems: "center",
                fontWeight: "bold",
                margin: "auto 0",
                fontSize: 32
            }} className="course-price" variant="h5" component="h3">
                <MdCurrencyRupee color="inherit" fontSize="inherit" />
                {value.discounted_price ? value.discounted_price : "0"}/-
            </Typography>
        </div>
    }

    adjustArrayLength = (array: AddCourse[], defaultThumbnail: string): AddCourse[] => {
        const maxLength = 4;
        if (array.length > maxLength) {
            return array.slice(0, maxLength);
        } else if (array.length < maxLength) {
            return [...array, ...Array(maxLength - array.length).fill({ course_thumbnail: defaultThumbnail } as AddCourse)];
        }
        return array;
    };

    getBundleCoursesWeb(item: BundleCourses, index: number) {
        const value = item.attributes;
        const isPurchasedOrCart = value.is_purchased || value.is_cart, isPurchased = value.is_purchased;
        const courseThumbnails = this.adjustArrayLength(value.add_course, value.bundle_course_thumbnail);
        return (
            <Link to={`/course/${item.id}`} className="card bundle-course">
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <CardContent style={{ padding: 0 }}>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 49%)", gridTemplateRows: "repeat(2, 49%)", width: "100%", columnGap: "10px", rowGap: "10px", padding: "16px 16px 0 16px", boxSizing: "border-box" }}>
                            {courseThumbnails.map((item) => <img src={item.course_thumbnail} className="bundle-course-image" alt="Course" />)}
                        </div>
                        <div
                            style={{ width: "100%", background: "#4C8491" }}
                        >
                            <div style={webStyle.aboutBundleCourse}>
                                <div style={{
                                    display: "flex",
                                    textAlign: "center",
                                    columnGap: "10.21px",
                                    alignItems: "center",
                                }}>
                                    <img
                                        alt="rate"
                                        src={star}
                                        height={14}
                                        width={14}
                                    />
                                    <Typography component="span" className="student-count-rating" style={{ color: "white" }}>
                                        {value.rating_data.avrage_rating} | {value.total_student} Students
                                    </Typography>
                                </div>
                                <div style={webStyle.chapterLectureTimeHead}>
                                    <img src={coursesIcon} width={18} height={18} />
                                    <Typography component="span" style={webStyle.chapterLectureTimeText}>
                                        {value.course_count} Courses
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Typography variant="h5" component="h3" className="course-short-desc" style={{ padding: "0 16px 16 16px" }}>
                            {value.bundle_name}
                        </Typography>
                    </CardContent>
                    <div style={webStyle.addToCartBundle}>
                        {this.showCoursePrice(value)}
                        <CartButton
                            variant="contained"
                            style={isPurchasedOrCart ? {cursor: "default", height: 49} : { height: 49 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "bundleCourses", index)
                            }}
                            className={
                                isPurchasedOrCart ?
                                    "added-cart-btn added-cart-text" :
                                    "add-cart-button add-cart-text"
                            }
                            data-test-id="addCartBundleCourseTestId"
                        >
                                    <Typography variant="body2" style={{display: "inline-flex", alignItems: "center", gap: 3}} component="strong">
                                        {isPurchasedOrCart ? <FaCircleCheck color="inherit" fontSize="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                                        <Typography variant="body2" component="strong">
                                            {cartText(isPurchased, isPurchasedOrCart)}
                                        </Typography>
                                    </Typography>
                        </CartButton>
                    </div>
                </div>
            </Link>
        );
    }
    CustomNextArrow = (props: CustomArrowProps) => {
        const { onClick } = props;
        return (
            <ArrowForwardIosIcon style={{fontSize: "1rem"}} onClick={onClick}/>
        );
      };
    CustomPrevArrow = (props: CustomArrowProps) => {
        const { onClick } = props;
        return (
            <ArrowBackIosIcon style={{fontSize: "1rem"}} onClick={onClick} />
        );
      };
    CustomSWPrevArrow = (props: CustomArrowProps) => {
        const { onClick } = props;
        return (
            <div data-test-id="SWPrevArrow" style={webStyle.forwardBackIconStd} onClick={onClick} >
                <img src={backArrowPage} width={48.57} height={48.57} />
            </div>
        )
    }
    CustomSWNextArrow = (props: CustomArrowProps) => {
        const { onClick } = props;
        return (
            <div data-test-id="SWNextArrow" style={webStyle.forwardBackIconStd} onClick={onClick}>
            <img src={forwardArrowPage} width={48.57} height={48.57}/>
        </div>
        )
    }

    renderLaunchCourses(launchCoursesSort: LaunchedCourses[]) {
        return <>{launchCoursesSort && launchCoursesSort.length > 0 ? launchCoursesSort.map((item: LaunchedCourses, index: number) => (
            <React.Fragment key={item.id}>
                {getNewLaunchCoursesWeb(item, index, CardType.newLaunchCourses, this.stringToBoolean, this.showCoursePrice, this.handleWishlistStatus, this.handleOpenCourseValidityPopup)}
            </React.Fragment>
        )) : this.showSkeleton()}</>
    }

    renderTrendingCourses(trendingCoursesSort: LaunchedCourses[]) {
        return <>
            {trendingCoursesSort && trendingCoursesSort.length > 0 ?
                trendingCoursesSort.map((item: LaunchedCourses, index: number) =>
                    <React.Fragment key={item.id}>
                        {getNewLaunchCoursesWeb(item, index, CardType.trendingCourses, this.stringToBoolean, this.showCoursePrice, this.handleWishlistStatus, this.handleOpenCourseValidityPopup)}
                    </React.Fragment>
                ) : this.showSkeleton()}</>
    }

    renderBundleCourses(bundleCoursesSort: BundleCourses[]) {
        return <>
            {bundleCoursesSort && bundleCoursesSort.length > 0 ?
                bundleCoursesSort.map((item: BundleCourses, index: number) =>
                    <React.Fragment key={item.id}>
                        {this.getBundleCoursesWeb(item, index)}
                    </React.Fragment>
                ) : this.showSkeleton(3)}
        </>
    }

    renderItemValidityDialog = () => {
        const {courseValidityDetails, selectedValidity,courseListname = "newLaunchCourses", CourseValidityDialog} = this.state;
       const courseDescription = courseListname === "bundleCourses"  ? courseValidityDetails?.attributes?.description : courseValidityDetails?.attributes?.short_description;
       const course_name = courseListname === "bundleCourses"  ? courseValidityDetails?.attributes?.bundle_name : courseValidityDetails?.attributes?.course_name
        return (<CustomDialog 
        className={'dialogContainer'} 
        open={CourseValidityDialog} 
        onClose={this.onClose} 
        style={{ padding: 24 }}>
        <DialogTitle disableTypography className={'dialogTitleRoot'}>
          <Typography data-test-id="courseValidityTitle" className={'dialogTitle'}>
            {configJSON.courseValidityTitle}
          </Typography>
          <IconButton className={'closeButton'} onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={'dialogContent'}>
          <Typography variant="subtitle1" className={'subTitleCourseValidity'}>
            {course_name}: {courseDescription}
          </Typography>
          <div className={'buttonGroup'}>
            <Button
              data-test-id="1_year"
              variant="outlined"
              color="primary"
              className={`${'button'} ${selectedValidity === 1 ? 'selected' : ''}`}
              onClick={() => this.handleSelectValidity(1)}
            >
              <span className={'buttonLabel'}>
                {selectedValidity === 1 && <CheckIcon className={'checkIcon'} />}
                {configJSON.validity1Year}
                <div style={{
                     position: 'absolute' as const, 
                     right: 0 , 
                     display: 'flex',
                     alignItems: 'center',
                     fontWeight: 600,
                      fontFamily: 'Inter',
                      fontSize: 24
                }}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails?.attributes?.first_year_price}</div>
              </span>
            </Button>
            {courseValidityDetails?.attributes?.second_year_price ?
              <Button
                data-test-id="2_year"
                variant="outlined"
                color="primary"
                className={`${'button'} ${selectedValidity === 2 ? 'selected' : ''}`}
                onClick={() => this.handleSelectValidity(2)}
              >
                <span className={'buttonLabel'}>
                  {configJSON.validity2Year}
                  {selectedValidity === 2 && <CheckIcon className={'checkIcon'} />}
                  <div style={{
                     position: 'absolute' as const, 
                     right: 0 , 
                     display: 'flex',
                     alignItems: 'center',
                     fontWeight: 600,
                      fontFamily: 'Inter',
                      fontSize: 24
                }}><LiaRupeeSignSolid className="rupeeIcon" /> {courseValidityDetails?.attributes?.second_year_price}</div>
                </span>
              </Button>
              : null}
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between',padding: "16px 24px" }}>
          <Button className={'cancelButton'} onClick={this.onClose}>Cancel</Button>
          <Button 
          data-test-id="applyButton" 
          variant="contained" 
          className={'applyButton'} 
          onClick={() => 
          this.handleChartStatus(
            courseValidityDetails?.attributes?.is_purchased || courseValidityDetails?.attributes?.is_cart, 
            courseValidityDetails.id, 
            courseValidityDetails.type,
            courseListname,
            selectedValidity)}>
            Apply
          </Button>
        </DialogActions>
      </CustomDialog>)
    }
    render() {
        const {ourAchievements, ourInstruction,currentIndex,studentReview ,WebTopBanner,value,activeSubject, educationMainCategory,educationSubCategory, educationChildSubject} = this.state;
        const currentInstructor = ourInstruction[currentIndex]?.attributes;
        const videoLink = configJSON.watchDemoVideoYoutubeLink;
        const videoId = this.getVideoId(videoLink);
        const imageUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
        const settings = {
            dots: false,
            infinite: this.isConditionalRending(ourInstruction.length === 1 , false , true),
            speed: 500,
            slidesToShow: this.getShowToSlide(ourInstruction.length),
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '0',
            focusOnSelect: true,
            nextArrow: <this.CustomNextArrow />,
            prevArrow: <this.CustomPrevArrow />,
            beforeChange: (current: number, next: number) => this.handleSlideChange(current, next),
          };
          const SWsettings = {
            dots: false,
            infinite: this.isConditionalRending(studentReview.length >= 3 , true , false),
            speed: 500,
            slidesToShow: this.isConditionalRending(studentReview.length >= 3 , 3 , studentReview.length),
            slidesToScroll: 1,
            nextArrow: <this.CustomSWNextArrow />,
            prevArrow: <this.CustomSWPrevArrow />,
            style: webStyle.studentReviews,
            className: 'custom-slider-class' ,
          }
          const SWBannersettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            appendDots: (dots:React.ReactNode) => (
              <div>
                <ul> {dots} </ul>
              </div>
            ),
          };
        const launchCoursesSort = this.handleCoursesPagination<LaunchedCourses>(this.state.newLaunchCourses, this.state.launchCoursePageNo, this.state.launchCoursePerPage),
          trendingCoursesSort = this.handleCoursesPagination<LaunchedCourses>(this.state.trendingCourses, this.state.trendingCoursePageNo, this.state.terndingCoursePerPage),
          bundleCoursesSort = this.handleCoursesPagination<BundleCourses>(this.state.bundleCourses, this.state.bundleCoursePageNo, this.state.bundleCoursePerPage);
        return (
            <ThemeProvider theme={theme}>
                <NotificationAlertMessage
                    alertType={this.state.alertType}
                    msg={this.state.alertMsg}
                    onClose={this.closemodal}
                    isOpen={this.state.isAlert}
                    data-test-id="notificationsAlertTestId"
                />
                {this.renderItemValidityDialog()}
                <Header navigation={this.props.navigation} id={""} />
                <div style={{ maxWidth: 1920, width: "100%", margin: "auto" }}>
                    <div style={{ ...webStyle.firstSecondMain }}>
                    <WebTopBannerSlider {...SWBannersettings}>
                    {WebTopBanner.filter(banner => banner.attributes.status).map((item: BannerListData, index: number) => (
                        <div style={{ ...webStyle.firstSecondMain }} key={index}>
                        <div style={{ ...webStyle.firstLayout,position: 'relative'}}>
                            <div style={{ ...webStyle.first }}>
                                <div style={{ width: "100%" }}>
                                <img src={item.attributes.banner_image} style={{cursor: "pointer", objectFit: "fill",borderBottomRightRadius: "95px",aspectRatio: 16/9, height: '100%' ,width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </WebTopBannerSlider>
                        {!this.state.userToken && (
                            <div style={{ ...webStyle.secondLayout, position: "relative" }}>
                                <div style={{ ...webStyle.addFriendTop, textAlign: "center" }}>How It Works?</div>
                                <div style={webStyle.second}>
                                    <div style={{ ...webStyle.addFriendMain, alignItems: "center", display: "flex", flexDirection: "column" }}>
                                        <img width={92.85} height={92.85} src={addFriend1} />
                                        <div style={{ ...webStyle.addFriendText, textAlign: "center", fontWeight: 600 }}>{configJSON.howItWorksSignupTitle}</div>
                                        <div style={{ ...webStyle.addFriendDesc, textAlign: "center", whiteSpace: "pre-line", lineHeight: "1.5", fontWeight: 400 }}>{configJSON.howItWorkSignupDesc}</div>
                                    </div>
                                    <div>
                                        <img width={92.85} height={92.85} style={{ filter: "invert(100%)" }} src={forwardArrow} />
                                    </div>
                                    <div style={{ ...webStyle.addFriendMain, alignItems: "center", display: "flex", flexDirection: "column" }}>
                                        <img width={92.85} height={92.85} src={addFriend2} />
                                        <div style={{ ...webStyle.addFriendText, textAlign: "center", fontWeight: 600 }}>{configJSON.howItWorksSelectCoursesTitle}</div>
                                        <div style={{ ...webStyle.addFriendDesc, textAlign: "center", whiteSpace: "pre-line", lineHeight: "1.5", fontWeight: 400 }}>{configJSON.howItWorksSelectCoursesDesc}</div>
                                    </div>
                                    <div>
                                        <img width={92.85} height={92.85} style={{ filter: "invert(100%)" }} src={forwardArrow} />
                                    </div>
                                    <div style={{ ...webStyle.addFriendMain, alignItems: "center", display: "flex", flexDirection: "column" }}>
                                        <img width={102} height={102} src={addFriend3} />
                                        <div style={{ ...webStyle.addFriendText, textAlign: "center", fontWeight: 600 }}>{configJSON.howItWorksLearningTitle}</div>
                                        <div style={{ ...webStyle.addFriendDesc, textAlign: "center", whiteSpace: "pre-line", lineHeight: "1.5", fontWeight: 400 }}>{configJSON.howItWorksLearningDesc}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={webStyle.containerMaxWidth}>
                    <div style={webStyle.courseLayout}>
                        <div style={{ ...webStyle.launcedCourseHead, marginBottom: "33px" }}>
                            <div style={webStyle.courseTitle}>New Launched Courses</div>
                            <Button endIcon={<ArrowForwardIcon htmlColor="#1721CD" />} variant="outlined" data-test-id="goLaunchedCourseList" style={webStyle.courseViewAll} onClick={() => this.handleViewCourseList("New Launched Courses")}>
                                <span style={webStyle.viewAllText}>{configJSON.viewAllText}</span>
                            </Button>
                        </div>
                        <LaunchTrendCard style={webStyle.launchedCoursesMain}>
                            <IconButton
                                data-test-id="backLaunchedCourse"
                                onClick={() => this.handleLauchedCoursePage("prev")}
                                className="change-page-button"
                                style={{ display: this.state.launchCoursePageNo === 1 ? "none" : "" }}
                                disabled={this.disabledBackButtons().launchedCourses}
                            >
                                <img src={backArrowPage} width={48.56} height={48.56} />
                            </IconButton>
                            <div className={`launchTrendCardMain slider-content ${this.state.transitionDirection}`}>
                                {this.renderLaunchCourses(launchCoursesSort)}
                            </div>
                            <IconButton
                                data-test-id="nextLaunchedCourse"
                                onClick={() => this.handleLauchedCoursePage("next")}
                                className="change-page-button"
                                style={{ ...webStyle.forwardIcon, display: this.state.launchCoursePageNo + 1 === this.state.launchCourseLastPage ? "none" : "" }}
                                disabled={this.disabledNextButtons().launchedCourses}
                            >
                                <img src={forwardArrowPage} width={48.56} height={48.56} />
                            </IconButton>

                        </LaunchTrendCard>
                    </div>
                    </div> 
                    <div style={webStyle.thirdLayout}>
                        <div style={{...webStyle.third, maxWidth: '1440px', margin: '0px auto'}}>
                            <div style={{ ...webStyle.thirdTopText, textAlign: "center" }}>{configJSON.educationStudentTitle}</div>
                            <div style={{ ...webStyle.thirdMidText, textAlign: "center" }}>{configJSON.educationStudentSubtitle}</div>
                        </div>
                    </div>
                    <div style={webStyle.eductionaCategories}>
                    <EducationalCategoryListWrapper>
                        <div className={'roots'}>
                            {this.isConditionalRending(this.state.loadingMainCategory , (
                                <Skeleton variant="rect" width="100%" height={50} style={{marginBottom: 16}}/>
                            ) ,
                            <Tabs value={value} onChange={this.handleChange} style={{ gap: 8 }} variant="scrollable" scrollButtons="auto" className={'tabs'}>
                                    {this.isConditionalRending(educationMainCategory.length > 0 , educationMainCategory.map((category: any) => (
                                        <Tab label={category.attributes.name} data-test-id="tabCategoryId" className={'tab'} onClick={() => this.getEducationSubCategory(category.id, category.attributes.name)} />
                                    )), null)}
                                </Tabs>)}
                            {Array.from({ length: 6 }).map((_, index) => (
                                <TabPanel key={index} value={this.isConditionalRending(value < 6 , value , 1)} index={index} >
                                    <div>
                                        <div className={"chipContainer scrollbar"}>
                                            {this.isConditionalRending(this.state.loadingSubCategory , (
                                                <Skeleton variant="rect" width="100%" height={50} />
                                            ) , (
                                                <>
                                                    {this.isConditionalRending(educationSubCategory.length > 0 , educationSubCategory.map((subCategory: any, index: number) => (
                                                        <Chip
                                                            data-test-id="subCategoryId"
                                                            icon={<img src={subCategory.attributes.web_icon}
                                                                style={{ backgroundColor: '00FFFFFF', width: 27, height: 27 }} />}
                                                            label={subCategory.attributes.name}
                                                            clickable
                                                            onClick={() => this.handleSubjectClick(index, subCategory.id, subCategory.attributes.name)}
                                                            className={`${'chip'} ${index === activeSubject ? 'activeChip' : ''}`}
                                                        />
                                                    )), <Typography data-test-id="emptySubcat" variant="h6" style={{ margin: '0px auto' }}>{this.state.emptySubCategories}</Typography>)}
                                                </>
                                            ))}
                                        </div>
                                        <Grid container spacing={3} style={{ width: '100%', overflowX: "scroll", flexWrap: 'nowrap' }} className={`${"subjectContainer"} ${"scrollbar"}`}>
                                            {this.isConditionalRending(this.state.loadingChildSubject , (
                                                Array.from({ length: 4 }).map((_, index) => (
                                                    <Grid item key={index}>
                                                        <Skeleton variant="rect" width={140} height={140} />
                                                    </Grid>
                                                ))
                                            ) , (
                                                this.isConditionalRending(educationChildSubject.length > 0 , educationChildSubject.map((subject: any, index: number) => (
                                                    <Grid item xs={12} sm={6} md={3} key={index} style={{ display: 'flex', borderLeft: index > 0 ? "1px solid rgba(0, 0, 0, 0.12)" : "" }}>
                                                        <Box data-test-id="EducationSubject" className={"cards"} onClick={() => this.handleNavigateEducationSubject(subject.id,subject.attributes.name)}>
                                                            <Box className="cardMain">
                                                                <img src={subject.attributes.web_icon}
                                                                    style={{ backgroundColor: '#FFFFFF', width: 90, height: 90 }}
                                                                />
                                                                <Typography className="education-subjectname" style={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    wordBreak: 'break-word',
                                                                    width: 150,
                                                                    // height: 88,
                                                                    WebkitLineClamp: 3,
                                                                    display: "-webkit-inline-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    textTransform: "capitalize"
                                                                }}>
                                                                    {subject.attributes.name.toLowerCase()}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )) , <Typography variant="h6" style={{ margin: '0px auto' }} >{this.state.emptyChildCategories}</Typography>)))}
                                        </Grid>
                                    </div>
                                </TabPanel>
                            ))}
                        </div>
                    </EducationalCategoryListWrapper>
                    <div>
                        <div style={{ ...webStyle.launcedCourseHead, marginBottom: "33px" }}>
                            <div style={webStyle.courseTitle}>Trending Courses</div>
                            <Button endIcon={<ArrowForwardIcon htmlColor="#1721CD" />} variant="outlined" data-test-id="goTrendingCourseList" style={webStyle.courseViewAll} onClick={() => this.handleViewCourseList("Trending Courses")}>
                                <span style={webStyle.viewAllText}>{configJSON.viewAllText}</span>
                            </Button>
                        </div>
                        <TrendCourseCard style={webStyle.launchedCoursesMain}>
                            <IconButton
                                data-test-id="backTrendingCourse"
                                onClick={() => this.handleTrendingCoursePage("prev")}
                                style={{ display: this.state.trendingCoursePageNo === 1 ? "none" : "" }}
                                className="change-page-button"
                                disabled={this.disabledBackButtons().trendingCourses}
                            >
                                <img src={backArrowPage} width={48.56} height={48.56} />
                            </IconButton>
                            <div className={`launchTrendCardMain slider-content ${this.state.transitionDirection}`}>
                                {this.renderTrendingCourses(trendingCoursesSort)}
                            </div>
                            <IconButton data-test-id="nextTrendingCourse"
                                onClick={() => this.handleTrendingCoursePage("next")}
                                className="change-page-button"
                                style={{ ...webStyle.forwardIcon, display: this.state.trendingCoursePageNo + 1 === this.state.trendingCourseLastPage ? "none" : "" }}
                                disabled={this.disabledNextButtons().trendingCourses}
                            >
                                <img src={forwardArrowPage} width={48.56} height={48.56} />
                            </IconButton>
                        </TrendCourseCard>
                    </div>
                    
                    <div style={webStyle.fifthLayout}>
                        <div style={webStyle.fifth}>
                            <div style={{...webStyle.fifthLeft,borderTopLeftRadius: 12, borderBottomLeftRadius: 12,padding: '32px 50px 0px 50px'}}>
                                <div style={{ ...webStyle.fifthTopText, textAlign: "center" }}>{configJSON.ourAchievementTitle}</div>
                                <div data-test-id="ourAchievementSubTitle" style={{ ...webStyle.fifthMidText, textAlign: "center" ,marginTop: 16}}>{configJSON.ourAchievementSubTitle}</div>
                                <div style={webStyle.fifthThirdMain}>
                                    <div style={webStyle.followersMain}>
                                        <div style={webStyle.iconDiv}>
                                            <img
                                                src={followers} style={webStyle.followerImg} />
                                        </div>
                                        <div style={webStyle.countFollowTextMain}>
                                            <div style={webStyle.count} data-test-id="totalFollowerId">{ourAchievements.total_followers}</div>
                                            <OurAchievementTitleText>{configJSON.totalFollowerText}</OurAchievementTitleText>
                                        </div>
                                    </div>
                                    <div style={webStyle.followersMain}>
                                        <div style={webStyle.iconDiv}><img style={webStyle.followerImg} src={teachers} /> </div>
                                        <div style={webStyle.countFollowTextMain}>
                                            <div style={webStyle.count}>{ourAchievements.certified_teachers}</div>
                                            <OurAchievementTitleText>{configJSON.certifiedTeacherText}</OurAchievementTitleText>
                                        </div>
                                    </div>
                                    <div style={webStyle.followersMain}>
                                        <div style={webStyle.iconDiv}>  <img style={webStyle.followerImg} src={courses} /></div>
                                        <div style={webStyle.countFollowTextMain}>
                                            <div style={webStyle.count}>{ourAchievements.online_courses}</div>
                                            <OurAchievementTitleText>{configJSON.onlineCoursesText}</OurAchievementTitleText>
                                        </div>
                                    </div>
                                    <div style={webStyle.followersMain}>
                                        <div style={webStyle.iconDiv}>  <img style={webStyle.followerImg} src={enrolled} /></div>
                                        <div style={webStyle.countFollowTextMain}>
                                            <div style={webStyle.count}>{ourAchievements.student_enrolled}</div>
                                            <OurAchievementTitleText>{configJSON.studentEnrollText}</OurAchievementTitleText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ ...webStyle.fifthRight, position: "relative" ,borderTopRightRadius: 12 , borderBottomRightRadius: 12}}>
                                <div style={{position: 'absolute', right: 0, top: '-50px' }}>
                                    <img src={orangePlanetWithDisk} width={'150px'} height={'80px'} />
                                    </div>
                                <div style={webStyle.fifthTopTextRight}>{configJSON.ourInstructorTitle}</div>
                                <div style={{margin: 12, height: 172, padding: '0px 24px'}}>
                                    <div style={{ ...webStyle.fifthTopMidRight, textAlign: "center", overflow: 'hidden',hyphens: 'auto', textOverflow: 'ellipsis', wordBreak: 'break-all', 
                                    fontSize: this.isConditionalRending(currentInstructor?.details.length > 120 , "25px" , '35px') }}>
                                        <img src={invertedCommaStart} style={{ marginRight:12 }} />  {this.truncateText(currentInstructor?.details, 200)} <img src={invertedCommaEnd} style={{ marginLeft:12 }} />
                                    </div>
                                </div>
                                <div style={webStyle.fifthThirdTextRight} data-test-id="instructor_fullname">{currentInstructor?.full_name}</div>
                                <div style={{ ...webStyle.instructorChildCatName }}>{currentInstructor?.leacture_in}</div>
                               <InstructorSliderBox style={{ width: this.getWidth(ourInstruction.length)}}>
                                <CustomSlider {...settings}>{ourInstruction.map((instructor: Instructor, index: number) => (
                                    <div key={index}>
                                        <Avatar data-test-id="instructor_pic" alt="Remy Sharp" src={instructor.attributes.profile_icon} onClick={() => this.handleSlideChange(currentIndex, index)} />
                                    </div>
                                ))}
                                </CustomSlider>
                                </InstructorSliderBox>
                            </div>
                        </div>
                    </div>
                    </div>
                    <CarouselDisplay navigation={this.props.navigation} id={""} />
                    <div style={webStyle.containerMaxWidth}>
                    <div>
                        <div style={webStyle.favStudyImgBundleMain}>
                            <div style={webStyle.launcedCourseHead}>
                                <div style={webStyle.courseTitle}>Bundle Courses</div>
                                <Button endIcon={<ArrowForwardIcon htmlColor="#1721CD" />} variant="outlined" data-test-id="goBundleCourseList" style={webStyle.courseViewAll} onClick={() => this.handleViewCourseList("Bundle Courses")}>
                                    <span style={webStyle.viewAllText}>{configJSON.viewAllText}</span>
                                </Button>
                            </div>
                            <div style={{ ...webStyle.favStudyImg, position: "absolute" }}>
                                <img src={favFolder} style={{ paddingTop: "60px" }} width={154} height={154} />
                                <img src={study} width={263.9} height={218} />
                            </div>
                        </div>
                        <div style={webStyle.bundleCourseMain}>
                            <IconButton
                                data-test-id="backBundleCourse"
                                className="change-page-button"
                                style={{ display: this.state.bundleCoursePageNo === 1 ? "none" : "" }}
                                disabled={this.disabledBackButtons().bundleCourses}
                                onClick={() => this.handleBundleCoursePage("prev")}
                            >
                                <img src={backArrowPage} width={48.56} height={48.56} />
                            </IconButton>
                            <div className="bundleCourseCardMain">
                                {this.renderBundleCourses(bundleCoursesSort)}
                            </div>
                            <IconButton 
                                data-test-id="nextBundleCourse"
                                onClick={() => this.handleBundleCoursePage("next")}
                                style={{ ...webStyle.forwardIcon, display: this.state.bundleCoursePageNo + 1 === this.state.bundleCourseLastPage ? "none" : "" }}
                                disabled={this.disabledNextButtons().bundleCourses}
                                className="change-page-button"
                            >
                                <img src={forwardArrowPage} width={48.56} height={48.56} />
                            </IconButton>
                        </div>
                    </div>
                    </div>
                    <Box position={'relative'} style={{backgroundColor: 'transparent',
                        paddingTop: 100
                    }}>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0, 
                        background: `url(${web_BG_Visibility})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     backgroundPosition: 'top',
                     width: '100%',
                     height: '100%',
                     opacity: "0.1",
                     zIndex: -1,
                     }} />
                     <div style={webStyle.containerMaxWidth}>
                    <DemoBox>
                        <Grid container spacing={8} alignItems="center" justify="center">
                            <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                <Box display="flex" flexDirection="column" style={{ gap: 40 }}>
                                    <Box display={"flex"} style={{ gap: 40 }}>
                                        <Box>
                                            <Typography data-test-id="watchOurTextId" variant="h4" className={"watchOurText"}>{configJSON.watchOurText}</Typography>
                                            <Typography variant="h4" className={"demoCourseText"}>{configJSON.demoCourse}</Typography>
                                            <Typography variant="subtitle1" className={"watchDemoText"} >{configJSON.watchFreeDemo}</Typography>
                                        </Box>
                                        <Box style={{ marginTop: "-60px" }}>
                                            <img src={book} style={{ width: 176, height: 168 }} />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <div className={"buttons"}>
                                            <Button variant="contained" data-test-id="Explore_now_Id" color="secondary" className={"button"} onClick={() => this.handleImageClick(videoLink)}>
                                                {configJSON.exploreBtnText}
                                            </Button>
                                            <Button variant="contained" data-test-id="viewdemoId" style={{ background: "#1220C1" }} className={"button"} onClick={() => this.handleImageClick(videoLink)}>
                                                {configJSON.viewDemoText}
                                            </Button>
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <iframe width="560" height="315" style={{display: "initial"}} className="imageContainer" src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </Grid>

                        </Grid>
                    </DemoBox>

                        <StudentWrapper style={{ position: "relative"
                    }}>
                    <div>
                        <div style={{...webStyle.studentHead,position: 'relative'}}>
                            <div>{configJSON.studentReviewText}</div>
                            <div style={{position: 'absolute', top: '-60px', right: '50%'}}><img src={reviewIdeaLamp} style={{width: '140px', height: '140px'}} /></div>
                            <div style={{position: 'absolute', top: '-50%', right: '15%'}}><img src={studentArrowGroup} style={{width: '180px', height: '130px'}} /></div>
                            </div>
                        <StudentReviewSlider {...SWsettings}>
                            {studentReview.map((review: StudentReviewData, index: number) => (
                                <div data-test-id="studentReviewData">
                                <div className="studentReview">
                                <div>
                                    <Avatar alt="Start" src={review.attributes.user_image} className="reviewStudentImg" style={webStyle.studentImg} />
                                </div>
                                <div style={{overflow: 'auto'}}>
                                    <div className={'studentReviewTop'}>
                                        <div className={'studentName'}>{review.attributes.user_name.full_name}</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <Rating value={review.attributes.rating} style={{ fontSize: '1rem', color: '#FFD735'}} readOnly />
                                    </div>
                                    <div className={'studentReviewText'}>
                                       {review.attributes.content}
                                    </div>
                                </div>
                            </div>
                            </div>
                            ))}
                        </StudentReviewSlider>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }} className={'bottomImage'}>
                    </div>
                    </StudentWrapper>  
                    </div> 
                    </Box>
                <Footer navigation={this.props.navigation} id={""} />
                </div>
            </ThemeProvider>
        );
    }
}

function cartText(isPurchased: boolean, isPurchasedOrCart: boolean) {
    if (isPurchased) return "Purchased";
    if (isPurchasedOrCart) return "Added";
    return "Add to Cart";
}

export function getNewLaunchCoursesWeb(item: LaunchedCourses, index: number, listName: CardType, stringToBoolean: (value: string)=>boolean,showCoursePrice: (value: BundleCoursesAttributes | LaunchedCoursesAttributes) => JSX.Element, handleWishlistStatus: (isAdd: boolean, courseId: string, listName: "newLaunchCourses" | "trendingCourses" | "bundleCourses") => void, handleOpenCourseValidityPopup: (isAdd: boolean,course: any,list: "newLaunchCourses" | "trendingCourses" | "bundleCourses", index: number) => void) {
    const value = item.attributes, isPurchasedOrCart = value.is_purchased || value.is_cart, isPurchased = value.is_purchased;
    const isSecondYearPriceValid = Boolean(value.second_year_price && value.second_year_price > 0);

    return (
        <Link to={`/course/${item.id}`} className="card">
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <CardContent>
                    <div>
                        <div style={{ position: "relative" }}>
                            <img
                                src={value.course_thumbnail}
                                className="card-thumbnail"
                                alt="Launched Course"
                                height={142}
                                width={"100%"}
                                style={{ aspectRatio: 16/9 }}
                            />
                            <img style={{ position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }} src={playIcon} alt="Launched Course" width={39} height={39} />
                        </div>
                    </div>
                    <div style={webStyle.aboutCourse}>
                        <div className="course-cat-name" style={{ borderColor: value.course_child_category.color, width: '42%' }}>
                            <CircleIcon fontSize="inherit" htmlColor={value.course_child_category.color} />
                            <Typography className="course-cat-name-text" variant="body2" component="strong">
                                {value.course_child_category && value.course_child_category.name}
                            </Typography>
                        </div>
                        <div style={{
                            display: "flex",
                            textAlign: "center",
                            columnGap: "8px",
                            alignItems: "center",
                            width: "45%"
                        }}>
                            <img
                                alt="rate"
                                src={star}
                                height={12}
                                width={12}
                            />
                            <Typography className="student-count-rating" variant="body2" component="span">
                                <Typography style={{ color: "black" }} variant="body2" component="strong">{value.average_rating.Average_rating}</Typography> | {value.student_count} Students
                            </Typography>
                        </div>
                        {stringToBoolean(value.is_wishlist) ? <FaHeart className="heartIconList heartIconList2" color="red" /> : <FaHeart className="heartIconList heartIconList2"
                  color={"lightgrey"} />}
                    </div>
                    <Typography className="course-short-desc" variant="h6" component="h3">
                        {value.course_name}
                    </Typography>
                </CardContent>
                <div
                    style={{
                        marginTop: "1px",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{ ...webStyle.instructorNameMain, boxSizing: "border-box", background: value.course_child_category.color }}
                    >
                        <Typography className="instructor-name" variant="subtitle2" component="span">
                            Instructor : {value.instructor_name}
                        </Typography>
                    </div>
                </div>
                <div style={webStyle.addToCart}>
                    {showCoursePrice(value)}
                    <CartButton variant="contained" className={
                        isPurchasedOrCart ?
                            "added-cart-btn added-cart-text" :
                            "add-cart-button add-cart-text"
                    }>
                        <PurchasedText isPurchased={isPurchased} isPurchasedOrCart={isPurchasedOrCart} />
                    </CartButton>
                </div>
            </div>
            <div className="card-content active">
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <CardContent style={{ flexGrow: 1 }}>
                        <div style={webStyle.courseNameTop}>
                            <Typography style={webStyle.courseName} variant="body1" component="strong">
                                {value.course_child_category.name}
                            </Typography>
                            <HeartIcon
                                className="wishlistIconWrapperList wishlistIconWrapperList2"
                                data-test-id="wishlistLaunchCourseTestId"
                                style={{ width: 30, height: 30, padding: 6}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleWishlistStatus(stringToBoolean(value.is_wishlist), item.id, listName);
                                }}
                            >
                                {stringToBoolean(value.is_wishlist) ? <FaHeart className="heartIconList heartIconList2" style={{ fontSize: 24 }} data-test-id="heartFilledTestId" color="red" /> : <FaHeart className="heartIconList heartIconList2" style={{ fontSize: 24 }} color={"#4C8491"} />}
                            </HeartIcon>
                        </div>
                        <div style={{...webStyle.aboutCourse2, minHeight: '110px'}}>
                            <Typography variant="h6" component="h3" style={{ ...webStyle.courseShortDescHover }} >{value.course_name}</Typography>
                            <Typography variant="subtitle1" component="strong" style={webStyle.fullDesc}>{value.short_description}</Typography>
                        </div>
                    </CardContent>
                    <div style={{ justifyContent: "space-between", marginBottom: "10.2px" }}>
                        <div style={webStyle.aboutCourseLeftRight}>
                            <div style={{ ...webStyle.aboutCourseLeft }}>
                                <Typography component="span" style={{ display: "flex", marginBottom: "10.55px" }}>
                                    <img src={star} width={12} height={11.7} />
                                    <Typography component="span" style={webStyle.chapterLectureTimeText}>{value.average_rating.Average_rating} | {value.student_count} Students</Typography>
                                </Typography>
                                <div style={webStyle.leftRightLable}>
                                    <Typography variant="subtitle2" component="span" style={webStyle.instructorLang}>INSTRUCTOR</Typography>
                                    <Typography variant="h6" component="span" style={webStyle.instructorLangName}>{value.instructor_name}</Typography>
                                </div>
                                <div style={webStyle.leftRightLable}>
                                    <Typography variant="subtitle2" component="span" style={webStyle.instructorLang}>LANGUAGE</Typography>
                                    <Typography variant="h6" component="span" style={webStyle.instructorLangName}>{value.language_name}</Typography>
                                </div>
                            </div>
                            <div className="divider" >
                                <div className="line" style={{ height: '130px' }}></div>
                            </div>
                            <div style={webStyle.aboutCourseRight}>
                                <div style={webStyle.leftRightLables}>
                                    <div style={webStyle.chapterLectureTimeHead}>
                                        <img src={chapter} style={{ filter: "invert(100%)", width: 17, height: 14 }} />
                                        <Typography component="span" style={webStyle.chapterLectureTimeText}>
                                            {value.total_content} Chapters
                                        </Typography>
                                    </div>
                                </div>
                                <img style={{ marginBottom: "5px" }} src={dashedLine} />
                                <div style={webStyle.leftRightLables}>
                                    <div style={webStyle.chapterLectureTimeHead}>
                                        <img src={lecture} style={{ filter: "invert(100%)", width: 17, height: 18 }} />
                                        <Typography component="span" style={webStyle.chapterLectureTimeText}>
                                            {value.course_class_count} Lectures
                                        </Typography>
                                    </div>
                                </div>
                                <img style={{ marginBottom: "6px" }} src={dashedLine} />
                                <div style={webStyle.leftRightLables}>
                                    <div style={webStyle.chapterLectureTimeHead}>
                                        <img src={time} style={{ filter: "invert(100%)", width: 18, height: 18  }} />
                                        <Typography component="span" style={webStyle.chapterLectureTimeText}>
                                            {value.all_course_class_duration.toFixed(2)} Minutes
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={webStyle.addToCart}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    columnGap: "10px",
                                }}>
                                <Typography style={{
                                    display: "inline-flex",
                                    columnGap: "10px",
                                    alignItems: "center",
                                    fontWeight: "bold"
                                }} variant="h5" component="h3" className="course-price">
                                    <MdCurrencyRupee color="white" fontSize="inherit" />
                                    {value.discounted_price ? value.discounted_price : "0"}/-
                                </Typography>
                                {isSecondYearPriceValid && 
                                    <DashedText style={{
                                        display: "inline-flex",
                                        columnGap: "10px",
                                        alignItems: "center",
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        color: '#fff'
                                    }} variant="body1" className="course-price-discount">
                                        <MdCurrencyRupee color="white" fontSize="inherit" />
                                        {value.first_year_price ? value.first_year_price : "0"}/-
                                    </DashedText>
                                }
                            </div>
                            <CartButton data-test-id="addCartLaunchCourseTestId" variant="contained"
                                style={isPurchasedOrCart ? {cursor: "default"} : {}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleOpenCourseValidityPopup(isPurchasedOrCart, item, listName, index)
                                }}
                                className={
                                    isPurchasedOrCart ?
                                        "added-cart-btn added-cart-text" :
                                        "add-cart-button add-cart-text"
                                }
                            >
                                <PurchasedText isPurchased={isPurchased} isPurchasedOrCart={isPurchasedOrCart} />
                            </CartButton>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

const PurchasedText = ({ isPurchased, isPurchasedOrCart }: { isPurchased: boolean, isPurchasedOrCart: boolean }) => {
    return <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
        {isPurchasedOrCart ? <FaCircleCheck color="inherit" fontSize="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
        <Typography variant="body2" component="strong">
            {cartText(isPurchased, isPurchasedOrCart)}
        </Typography>
    </Typography>
}

export const DashedText = styled(Typography)({
    position: 'relative',
    display: 'inline-block',

});

export const HeartIcon = styled(IconButton)({
    borderRadius: "50%",
    fontSize: "0.875rem",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.27)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.8)"
  }
});

export const webStyle = {
    studentReviews: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 50
      },
    studentImg: { 
        width: "92px", 
        height: "92px",
        marginTop: 30
     },
    studentName: {
        color: "#212121",
        fontSize: "14.724px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "75.826px",
        letterSpacing: "-0.028px",
    },
    studentReviewTop: {
        display: "flex",
        alignItems: "center",
        columnGap: "5px",
        height: 54
    },
    firstSecondMain: {
    },
    demoCourseMain: {
        display: "flex",
        marginBottom: "139px",
        columnGap: "5%",
        marginTop: "100px",
        rowGao: "30px"
    },
    sixthLayout: {
        width: "1358px",
        borderRadius: "27px",
        background: "#042D6E"
    },
    demoMidText: {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: " 56.724px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "75.826px",
        letterSpacing: "-0.07px",
    },
    studentReview: {
        // width: "27%",
        border: "1px solid rgba(0, 0, 0, 0.18)",
        borderRadius: "18.72px",
        background: "#FFF",
        boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.18)",
        display: "flex",
        columnGap: "15px",
        padding: "20px",
        margin: '0px 20px',
        gap: 20,
        alignItems: "center",
        minHeight: '150px'
    },
    demoTextTop: {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: "40.724px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "75.826px",
        letterSpacing: "-0.05px",
        marginTop: "44px"
    },
    studentReviewLayout: {
        display: "flex",
        columnGap: "20px",

    },
    aboutBundleCourse: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px",
        marginBottom: "10px",
        padding: "10px"
    },
    viewDemoText: {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: "22.633px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.028px",
        marginTop: "19px",
        marginBottom: "20px"
    },
    heartRed: {
        borderRadius: "26.81px", width: "25.1px", height: "25.1px", backgroundColor: "#FFF", display: "flex", alignItems: "center", justifyContent: "center"
    },
    referNow: {
        width: "282px",
        height: "65px",
        flexShrink: 0,
        borderRadius: "32.582px",
        background: "#3784F8",
    },
    studentReviewText: {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: "15px",
        fontStyle: "normal",
        lineHeight: "normal",
        marginTop: "8px",
        fontWeight: 400,
        letterSpacing: "-0.018px",
    },
    avatarPagination: {
        display: "flex",
        justifyContent: "center"
    },
    favStudyImg: {
        display: "flex",
        justifyContent: "flex-end",
        right: "138px",
        paddingTop: "31px",
        zIndex: 2
    },
    favStudyImgBundleMain: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative"
    } as Record<string, string>,
    referText: {
        color: "#02C5C8",
        fontFamily: 'Inter',
        fontSize: "13.757px",
        fontStyle: "normal",
    },
    earnText: {
        color: "#3784F8",
        fontFamily: 'Inter',
        fontSize: "13.757px",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    referNowText: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: "20.997px",
        fontStyle: "normal",
        lineHeight: "normal",
    },
    selectedItem: {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: "20.941px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.087px",
        diplay: "flex",
        justifyContent: "center"
    },
    bundleCourseMain: {
        display: "flex",
        background: "linear-gradient(180deg, rgba(255,232,246,1) 25%, rgba(255,255,255,1) 100%)",
        marginTop: "50px",
        paddingTop: "62px",
        position: "relative"
    } as Record<string, string>,
    sixth: {},
    fifthLayout: {
        margin: '80px 24px 60px 24px'
    },
    fifth: {
        display: "flex"
    },
    fifthLeft: {
        width: "50%",
        background: "linear-gradient(135deg, #5040FF 0%, #00222A 100%)",
        borderTopRightRadius: "58px",
        borderBottomRightRadius: "58px",
        paddingTop: "32px",
        zIndex: 7
    },
    fifthThirdMain: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        rowGap: "33px",
        paddingTop: "55px",
        paddingBottom: "69px"
    },
    iconDiv: {
        width: "83px",
        height: "83px",
        flexShrink: 0,
        borderRadius: "16px",
        background: "#00165F",
    },
    followersMain: {
        display: "flex",
        columnGap: "16px",
        marginLeft: "20px"
    },
    countFollowTextMain: {},
    count: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: "38.633px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "61px",
        letterSpacing: "-0.047px",

    },
    countText: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: " 20.633px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.025px",
        fontWeight: 400
    },
    fifthRight: {
        width: "50%",
        background: "#98F2FF",
        paddingLeft: "100px",
        marginLeft: "-90px",
        paddingTop: "32px"
    },
    fifthTopText: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: "49.633px",
        fontStyle: "normal",
        lineHeight: "61px",
        letterSpacing: "-0.061px",
        fontWeight: 700,
    },
    fifthTopTextRight: {
        color: "#00096F",
        fontFamily: 'Inter',
        fontSize: "49.633px",
        fontStyle: "normal",
        lineHeight: "61px",
        letterSpacing: "-0.061px",
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
        fontWeight: 700
    },
    fifthThirdTextRight: {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: "28.633px",
        fontStyle: "normal",
        lineHeight: "61px",
        letterSpacing: "-0.035px",
        display: "flex",
        justifyContent: "center",

        marginTop: "29px",
        fontWeight: 400
    },
    fifthTopMidRight: {
        color: "#000",
        fontFamily: 'Inter',
        fontSize: "33.633px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.041px",
        fontWeight: 400
    },
    fifthMidText: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: "22.633px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.028px",
        fontWeight: 400
    },
    thirdLayout: {
        padding: '36px',
        boxShadow: '0px 4px 7px 0px #0000001A',
        position: 'sticky' as const,
        zIndex: 7
    },
    third: {

    },
    thirdMidText: {
        color: "#5A5A5A",
        fontFamily: 'Inter',
        fontSize: "24.633px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.103px",
        margin: "15px 6%",
        fontWeight: 400,
        '@media (min-width:1440px) and (max-width:1920px)': {
        margin: "15px 100px",
        }
    },
    thirdTopText: {
        color: "#3A0997",
        fontFamily: 'Inter',
        fontSize: "47.633px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.198px",
        marginBottom: "23px",
        fontWeight: 600,
    },
    courseLayout: {
        marginTop: "67px",
        marginBottom: "28px",
    },
    viewAllText: {
        color: "#1220C1",
        fontFamily: 'Inter',
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.3px",

    },
    instructorChildCatName: {
        color: "#212121",
        fontFamily: 'Inter',
        fontSize: "20.633px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.025px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "center"
    },
    courseTitle: {
        color: "#212121",
        fontSize: "32px",
        fontStyle: "normal",
        letterSpacing: "-0.049px",
        fontWeight: 600,
        lineHeight: "61px"
    },
    studentHead: {
        color: "#212121",
        fontSize: "32px",
        fontStyle: "normal",
        letterSpacing: "-0.049px",
        marginLeft: "61px",
        fontWeight: 600,
        lineHeight: "61px"
    },
    courseShortDescHover: {
        color: "#FFF",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.3px",
        fontWeight: 600,
        marginTop: "9px",
        marginBottom: "9px",
        display: "-webkit-box",
        webkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
        WebkitLineClamp: 2,
        overflow: 'hidden',
    },
    instructorNameMain: {
        width: "100%",
        padding: 7,
        background: "#0E599D",
        marginBottom: "6px",
        display: "flex",
        justifyContent: "flex-start"
    },
    followerImg: {
        filter: "invert(100%)",
        padding: "13px",
    },
    addFriendTop: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: "39.633px",
        fontStyle: "normal",
        lineHeight: "61px",
        letterSpacing: "-0.049px",
        marginTop: "33px",
        fontWeight: 700
    },
    secondLayout: {
        background: "#448797",
        borderBottomLeftRadius: "95px",
        paddingTop: "100px",
        marginTop: "-90px",
        zIndex: -1


    },
    instructorLang: {
        color: "#FFF",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.011px",
        fontSize: '8.96px'
    },
    instructorLangName: {
        color: "#FFF",
        fontStyle: "normal",
        letterSpacing: "-0.017px",
        fontSize: '14.11px',
        lineHeight: '17.07px'
    },
    chapterLectureTimeHead: {
        display: "flex",
        columnGap: "7.5px",
        alignItems: 'center'
    },
    chapterLectureTimeText: {
        color: "#FFF",
        fontStyle: "normal",
        lineHeight: "normal",
        marginLeft: 3,
        fontSize: '12.56px'
    },
    second: {
        display: "flex",
        columnGap: "20px",
        padding: "30px",
        alignIem: "center"

    },
    addFriendMain: {

    },
    addFriendDesc: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: "22.633px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.028px",
        fontWeight: 400
    },
    addFriendText: {
        color: "#FFF",
        fontFamily: 'Inter',
        fontSize: "32.633px",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.04px,",
        marginTop: "14px",
        fontWeight: 600
        // textAlign:"center"
    },
    firstLayout: {
        background: "linear-gradient(135deg, #3C7481 0%, #154955 100%)",
        borderBottomRightRadius: "95px",
    },
    firstText: {
        color: "#FFF",
        // fontFamily: "ProximaNova",
        fontSize: "65.633px",
        // fontStyle: "normal",
        // lineHeight: "69px",
        fontWeight: 700,
        // letterSpacing: "-0.08px",
        marginBottom: "13px"
    },
    first: {
        display: "flex",
        columnGap: "5px",

    },
    productContainer: {
    },
    aboutCourseLeft: {
        gap: '12px',
        display: 'flex',
        flexDirection: 'column' as const
    },
    launcedCourseHead: {
        display: "flex",
        columnGap: "43px",
        marginLeft: "61px",
        marginTop: "96px",
    },
    courseViewAll: {
        display: "flex",
        columnGap: "10px",
        borderRadius: "31.826px",
        border: "1.8px solid #1721CD",
        background: "#FFF",
    },
    studentCountRatingBundle: {
        color: "#FFF",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    },
    leftRightLable: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginBottom: "10px"
    } as CSSProperties,
    leftRightLables: {
        marginBottom: "10px"
    },
    aboutCourseRight: {
        gap: '8px',
        display: 'flex',
        flexDirection: 'column' as const,
    },
    aboutCourseLeftRight: {
        display: "flex",
        gap: "10px",
        color: "white",
        padding: "0 16px 16px 16px",
        justifyContent: "space-between"
    },
    shortDesc: {
        color: "white",
        marginTop: "10px",
        marginBottom: "10px"
    },
    fullDesc: {
        color: " #FFF",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "-0.151px",
        display: "-webkit-box",
        webkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
        WebkitLineClamp: 3,
        overflow: 'hidden',
    },
    aboutCourse2: {
        marginTop: "10px"
    },
    courseNameTop: {
        display: "flex",
        justifyContent: "space-between"
    },
    addToCart: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "0px 16px",
        boxSizing: "border-box",
        margin: "12px 0px 24px 0px"
    } as Record<string, string | number>,
    addToCartBundle: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "0px 16px",
        boxSizing: "border-box",
        margin: 'auto', 
        marginBottom: 12
    } as Record<string, string | number>,
    withHover: {
        borderRadius: "14.055px",
        opacity: 0.15,
        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.27)",
        background: "linear-gradient(180deg, rgba(0, 33, 41, 0.98) 0%, rgba(32, 104, 120, 0.95) 100%)",
    },
    root: {
        flexGrow: 1,
    },
    launchTrendCardMain: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(23%, 1fr))",
        gap: "10px",
        width: "100%",
        transition: "transform 0.5s ease-in-out"
    },
    paper: {
        height: 140,
        width: 100,
    },
    aboutCourse: {
        display: "flex",
        columnGap: "11px",
        marginTop: "14.3px",
        justifyContent: "space-between",
        width: "100%",
        alignItems: 'center'
    },
    forwardIcon: {
        right: 0,
    } as Record<string, string | number>,
    forwardBackIconStd: {
        cursor: "pointer",
    },
    launchedCoursesMain: {
        display: "flex",
        position: "relative",
         transition: "0.3s"
    } as Record<string, string>,
    courseName: {
        border: "1px solid white",
        background: "white",
        color: "#1220C1",
        width: "max-content",
        borderRadius: "26.85px",
        padding: "5px 7px",
        height: "min-content",
    },
    productBox: {
        height: 250,
        width: "49%",
        marginRight: "5px",
        flexDirection: "column",
    },
    ImgContainer: {
        height: 150,
    },
    productImg: {
        width: "100%",
        height: "100%",
    },
    detailContent: {
        display: "flex",
        flexDirection: "column",
    },
    firstCircle: {
        width: "260px",
        height: "260px",
        border: "1px solid #979797",
        opacity: 0.5,
        borderRadius: "50%",
        position: "absolute",
        marginRight: "267px",
        top: "11px"
    },
    secondCircle: {
        width: "349px",
        height: "349px",
        border: "1px solid #979797",
        opacity: 0.5,
        borderRadius: "50%",
        position: "absolute",
        marginRight: "223px",
        top: "-5px"
    },
    thirdCircle: {
        width: "428px",
        height: "428px",
        border: "1px solid #979797",
        opacity: 0.5,
        borderRadius: "50%",
        position: "absolute",
        marginRight: "183px",
        top: "-45px"
    },
    fourthCircle: {
        width: "498px",
        height: "498px",
        border: "1px solid #979797",
        opacity: 0.5,
        borderRadius: "50%",
        position: "absolute",
        marginRight: "148px",
        top: "-61px"
    },
    fifthCircle: {
        width: "583px",
        height: "583px",
        border: "1px solid #979797",
        opacity: 0.5,
        borderRadius: "50%",
        poition: "absolute",
        marginRight: "107px",
        top: "-117px"
    },
    parentCircle: {
        right: 0,
        display: "flex",
        justifyContent: "flex-end",
        top: "-90px",
        zIndex: 1
    },
    searchBox: {
        width: "100%",
        height: "86px",
        maxWidth: 764,
        border: "2.372px solid white",
        bordeRadius: "30px",
        padding: "10px",
        marginTop: "30px !important",
        background: "rgba(255, 255, 255, 0.25)",
        backdropFilter: "blur(28.73729705810547px)",
        display: "flex",

    },
    selectWrapper: {
        display: "flex",

        position: "relative",
        width: "100%",
        padding: "10px 20px",
        borderRadius: "31.427px",
        background: "linear-gradient(180deg, #FFF54B 0%, #FCB910 100%)",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset",
    },
    select: {
        width: "100%",
        border: "none",
        background: "none",
        outline: "none",
        fontSize: "1.1rem",
        color: "#212121",
        // appearance: "none",
    },

    searchWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "10px",
        borderRadius: "31.427px",
    },
    input: {
        outline: "none",
        background: "none",
        border: "none",
        color: " #fff",
        fontSize: "1rem",
    },
    searchIcon: {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        background: "linear-gradient(180deg, #FFF54B 0%, #F4C952 100%)",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12) inset",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    icon: {
        fontSize: "1.2rem",
        color: "#212121",
      },
    containerMaxWidth: {
        maxWidth: 1440,
        margin: '0px auto'
    },
    eductionaCategories: {
        maxWidth: '1440px', 
        margin: '0px auto',
        background: 'transparent', 
        backgroundColor: 'transparent'
    }
};


