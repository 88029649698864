// Customizable Area Start
import React, { CSSProperties } from "react";
import {
  Box,
  Button,
  styled,
  CardContent,
  Chip,
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  DialogTitle, 
  DialogContent,  
  DialogActions,
  Typography
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MdOutlineWindow } from "react-icons/md";
import { TbLayoutList } from "react-icons/tb";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaCircleCheck, FaHeart } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { Close as CloseIcon ,Check as CheckIcon} from '@material-ui/icons';
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import AddIcon from "@material-ui/icons/Add";
import { MdCurrencyRupee } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import { IoChevronDown, IoLanguageSharp } from "react-icons/io5";
import { PiProjectorScreenChart } from "react-icons/pi";
import WishlistController, {
  Props,
  WishlistData,
  configJSON
} from "./WishlistController.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
import {
  study,
  playIcon,
  star,
  bg,
  chapter,
  lecture,
  time,
  dashedLine
} from "./assets";
import FooterWeb from "../../../components/src/Footer.web";
import HeaderWeb from "../../../components/src/Header.web";
import { CustomDialog } from "../../landingpage/src/LandingPage.web";

const CartButton = styled(Button)(() => ({
  padding: "10px 26px",
  borderRadius: "27px",
  minHeight: "56px",
  boxShadow: "unset",
  "& .MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: 4
  }
}));
const webStylesLocal: Record<string, CSSProperties> = {
  leftRightLable: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginBottom: "10px"
  },
  leftRightLables: {
    marginBottom: "10px"
  },
  aboutCourseLeftRight: {
    display: "flex",
    gap: "10px",
    color: "white",
    padding: "0 16px 16px 16px",
    justifyContent: "space-between"
  },
  instructorLang: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.011px"
  },
  instructorLangName: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.017px"
  },
  chapterLectureTimeHead: {
    display: "flex",
    columnGap: "7.5px"
  },
  chapterLectureTimeText: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    marginLeft: 3
  },
  aboutCourseRight: {
    rowGap: "10px"
  },
  fullDesc: {
    margin: "12px 0",
    padding: 0,
    color: " #FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.151px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    overflow: "hidden"
  },
  courseShortDescHover: {
    color: "#FFF",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px",
    fontWeight: 600,
    marginTop: "9px",
    marginBottom: "9px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    overflow: "hidden"
  },
  aboutCourse2: {
    marginTop: "10px"
  },
  courseName: {
    border: "1px solid white",
    background: "white",
    color: "#1220C1",
    width: "max-content",
    borderRadius: "26.85px",
    padding: "5px 7px",
    height: "min-content",
  },
  courseNameTop: {
    display: "flex",
    justifyContent: "space-between"
  },
  bundleCourseMainLayout: {
    width: "100%",
    margin: "0 auto",
  },
  addToCart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 16px",
    boxSizing: "border-box",
    margin: "auto"
  },
  addToCartList: {
    display: "flex",
    width: "20%",
    padding: "16px",
    boxSizing: "border-box",
    margin: "auto",
    alignItems: "end",
    justifyContent: "end"
  },
  instructorNameMain: {
    width: "100%",
    padding: 7,
    background: "#0E599D",
    marginBottom: "6px",
    display: "flex",
    justifyContent: "flex-start"
  },
  aboutCourse: {
    display: "flex",
    columnGap: "11px",
    marginTop: "14.3px",
    justifyContent: "space-between",
    width: "100%"
  },
  aboutCourseList: {
    display: "inline-flex",
    gap: "25px",
    width: "100%",
    alignItems: "center"
  },
  adminLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center",
    padding: "0 12px",
    boxSizing: "border-box"
  },
  listItem: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid lightgrey",
    boxSizing: "border-box",
    padding: "1rem 0",
    alignItems: "center"
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    width: "25%"
  },
  headerContainer: {
    display: "flex",
    color: "white",
    position: "relative",
    width: "100%"
  },
  headerImage: {
    maxWidth: 300,
    position: "absolute",
    top: "-15px",
    right: 0
  },
};

let theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Inter', monospace"
    },
    body1: {
      fontFamily: "'Inter', monospace"
    }
  }
});
theme = responsiveFontSizes(theme);

export default class Wishlist extends WishlistController {
  constructor(props: Props) {
    super(props);
    this.closemodal = this.closemodal.bind(this);
  }

  closemodal() {
    this.oncloseAlert();
  }

  dateToText(date: string) {
    const toDate = new Date(date);

    const day = String(toDate.getDate()).padStart(2, "0");
    const month = toDate
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase();
    const year = toDate.getFullYear();

    return `${day} ${month}, ${year}`;
  }

  stringToBoolean(value: string): boolean {
    return value.toLowerCase() === "true";
  }

  courseList(item: WishlistData, index: number) {
    const { attributes } = item,
      value = attributes.courses.data.attributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart, isPurchased = value.is_purchased;
    return (
      <Link to={`/course/${item.id}`} className="card_list wishlist-card">
        <div style={{ display: "inline-flex", width: "100%", height: "100%" }}>
          <CardContent style={{ width: "20%" }}>
            <div style={{ position: "relative", display: "flex", width: "100%", height: "100%" }}>
              <img
                src={value.course_thumbnail}
                style={{ borderRadius: "10.61px", height: "100%", width: "100%" }}
                alt="Launched Course"
                height={180}
                width={"100%"}
              />
              <img
                style={{
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%"
                }}
                src={playIcon}
                alt="Launched Course"
                width={39}
                height={39}
              />
            </div>
          </CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "60%",
              padding: "16px"
            }}
          >
            <div style={webStylesLocal.aboutCourseList}>
              <div
                className="course-cat-name"
                style={{
                  borderColor: value.course_child_category.color,
                  padding: 0
                }}
              >
                <CircleIcon
                  fontSize="inherit"
                  htmlColor={value.course_child_category.color}
                />
                <Typography variant="body2" component="strong" className="course-cat-name-text">
                    {value.course_child_category &&
                      value.course_child_category.name}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  columnGap: "8px",
                  alignItems: "center"
                }}
              >
                <img
                  alt="rate"
                  src={star}
                  height={12}
                  width={12}
                />
                <Typography className="student-count-rating" variant="body2" component="span">
                  <Typography style={{ color: "black" }} variant="body2" component="strong">
                    {value.average_rating.Average_rating}
                  </Typography>{" "}
                  | {value.student_count} Students
                </Typography>
              </div>
              <div>
                <div
                  className="instructor-name"
                  style={{
                    color: "#4C8491",
                    alignItems: "center",
                    gap: 5,
                    padding: 0
                  }}
                >
                  <PiProjectorScreenChart
                    fontSize={"inherit"}
                    color="inherit"
                  />
                  <Typography component="span">Instructor : {value.instructor_name}</Typography>
                </div>
              </div>
            </div>
            <Typography variant="h6" component="h3" className="course-short-desc">{value.course_name}</Typography>
          </div>
          <div style={webStylesLocal.addToCartList}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "end",
                width: "100%"
              }}
            >
              <Typography
                style={{
                  display: "inline-flex",
                  columnGap: "10px",
                  alignItems: "center"
                }}
                variant="h5" component="h3"
                className="course-price"
              >
                <MdCurrencyRupee color="inherit" fontSize="inherit" />
                {value.first_year_price}/-
              </Typography>
              {value.second_year_price && (
                <Typography
                  style={{
                    display: "inline-flex",
                    columnGap: "10px",
                    alignItems: "center"
                  }}
                  className="course-price-discount"
                  variant="body1"
                >
                  <MdCurrencyRupee color="inherit" fontSize="inherit" />
                  {value.second_year_price}/-
                </Typography>
              )}
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "end"
                }}
              >
                <IconButton
                  style={{
                    background: value.course_child_category.color,
                    padding: 5,
                    fontSize: 16,
                    borderRadius: 15,
                    color: "white"
                  }}
                  data-test-id="removeFromWishlistTestId"
                  onClick={() =>
                    this.removeFromWishlist(
                      item.attributes.courses.data.id,
                      index
                    )
                  }
                >
                  <FaRegTrashAlt />
                </IconButton>
                <CartButton
                  variant="contained"
                  className={
                    isPurchasedOrCart
                      ? "added-cart-btn added-cart-text"
                      : "add-cart-button add-cart-text"
                  }
                  data-test-id="addToCartTestId"
                  onClick={(e) =>
                     {
                      e.stopPropagation();
                      e.preventDefault();
                      !isPurchasedOrCart && this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "trendingCourses", index);
                    }
                  }
                >
                  <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                    {isPurchasedOrCart ?  <FaCircleCheck fontSize="inherit" color="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                    <Typography variant="body2" component="strong">
                      {isPurchased ? "Purchased" : isPurchasedOrCart ?
                        "Added" :
                        "Add to Cart"
                      }
                    </Typography>
                  </Typography>
                </CartButton>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "end",
                  alignItems: "center",
                  boxSizing: "border-box",
                  paddingBottom: "10px"
                }}
              >
                <Typography component="span">
                  ADDED ON <strong>{this.dateToText(value.created_at)}</strong>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        {/* CARD HOVER */}
        <div
          style={{ display: "inline-flex", width: "100%", height: "100%" }}
          className="card-content active"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              padding: "16px",
              justifyContent: "space-between"
            }}
          >
            <div style={webStylesLocal.aboutCourseList}>
              <Typography style={webStylesLocal.courseName} variant="body1" component="strong">
                {value.course_child_category.name}
              </Typography>
              <Typography
                variant="h6" component="h3"
                style={{
                  ...webStylesLocal.courseShortDescHover,
                  margin: 0,
                }}
              >
                {value.course_name}
              </Typography>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{ ...webStylesLocal.aboutCourse2, margin: 0 }}>
                <Typography
                  className="description-wrapper"
                  variant="subtitle1" 
                  component="strong"
                  style={{
                    ...webStylesLocal.fullDesc,
                    height: 48
                  }}
                >
                  {value.short_description}
                </Typography>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "0.9em",
                  alignItems: "center"
                }}
              >
                <div style={webStylesLocal.chapterLectureTimeHead}>
                  <img src={chapter} style={{ filter: "invert(100%)" }} />
                  <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                    {value.total_content} Chapters
                  </Typography>
                </div>
                <div style={webStylesLocal.chapterLectureTimeHead}>
                  <img src={lecture} style={{ filter: "invert(100%)" }} />
                  <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                    {value.course_class_count} Lectures
                  </Typography>
                </div>
                <div style={webStylesLocal.chapterLectureTimeHead}>
                  <img src={time} style={{ filter: "invert(100%)" }} />
                  <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                    {this.formatNumber(value.all_course_class_duration)} Minutes
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    columnGap: "10.21px",
                    alignItems: "center"
                  }}
                >
                  <img
                    alt="rate"
                    src={star}
                    height={12}
                    width={12}
                  />
                  <Typography
                    className="student-count-rating"
                    variant="body2"
                    component="span"
                    style={{ color: "#c7c7c7" }}
                  >
                    <Typography variant="body2" component="strong" style={{ color: "white" }}>
                      {value.average_rating.Average_rating}
                    </Typography>{" "}
                    | {value.student_count} Students
                  </Typography>
                </div>
                <div>
                  <div
                    className="instructor-name"
                    style={{
                      color: "white",
                      alignItems: "center",
                      gap: 5,
                      padding: 0
                    }}
                  >
                    <PiProjectorScreenChart
                      fontSize={"inherit"}
                      color="inherit"
                    />
                    <Typography variant="subtitle2" component="span">Instructor : {value.instructor_name}</Typography>
                  </div>
                </div>
                <div
                  className="instructor-name"
                  style={{
                    color: "white",
                    alignItems: "center",
                    gap: 5,
                    padding: 0
                  }}
                >
                  <IoLanguageSharp fontSize={"inherit"} color="inherit" />
                  <Typography component="span" style={webStylesLocal.instructorLang}>
                    Language {value.language_name}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div style={webStylesLocal.addToCartList}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "end",
                width: "100%"
              }}
            >
              <Typography
                style={{
                  display: "inline-flex",
                  columnGap: "10px",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "white"
                }}
                variant="h5" component="h3"
                className="course-price"
              >
                <MdCurrencyRupee color="inherit" fontSize="inherit" />
                {value.first_year_price}/-
              </Typography>
              {value.second_year_price && (
                <Typography
                  style={{
                    display: "inline-flex",
                    columnGap: "10px",
                    alignItems: "center",
                    color: "#f2f2f2"
                  }}
                  className="course-price-discount"
                  variant="body1"
                >
                  <MdCurrencyRupee color="inherit" fontSize="inherit" />
                  {value.second_year_price}/-
                </Typography>
              )}
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "end"
                }}
              >
                <IconButton
                  style={{
                    background: value.course_child_category.color,
                    padding: 5,
                    fontSize: 16,
                    borderRadius: 15,
                    color: "white"
                  }}
                  data-test-id="removeFromWishlistTestId"
                  onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.removeFromWishlist(
                        item.attributes.courses.data.id,
                        index
                      );
                    }
                  }
                >
                  <FaRegTrashAlt />
                </IconButton>
                <CartButton
                  data-test-id="addToCartTestId"
                  variant="contained"
                  onClick={(e) =>
                    {
                      e.stopPropagation();
                      e.preventDefault();
                      !isPurchasedOrCart && this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "trendingCourses", index);
                    }
                  }
                  className={
                    "add-cart-btn__wishlist add-cart-btn__wishlist-text"
                  }
                >
                  <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                    {isPurchasedOrCart ? <FaCircleCheck color="inherit" fontSize="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                    <Typography variant="body2" component="strong">
                      {isPurchased ? "Purchased" : isPurchasedOrCart ?
                        "Added" :
                        "Add to Cart"
                      }
                    </Typography>
                  </Typography>
                </CartButton>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "end",
                  alignItems: "center",
                  boxSizing: "border-box",
                  paddingBottom: "10px",
                  color: "white"
                }}
              >
                <Typography component="span">
                  ADDED ON <strong>{this.dateToText(value.created_at)}</strong>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  formatNumber(num: number) {
    return Number.isInteger(num) ? num : parseFloat(num.toFixed(2));
  };

  courseCard(item: WishlistData, index: number) {
    const { attributes } = item,
      value = attributes.courses.data.attributes,
      isPurchasedOrCart = value.is_purchased || value.is_cart, isPurchased = value.is_purchased;
    return (
      <Link
        to={`/course/${item.attributes.courses.data.id}`}
        className="card wishlist-card"
        data-test-id="courseCardTestId"
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <CardContent>
            <div>
              <div
                style={{
                  display: "inline-flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxSizing: "border-box",
                  paddingBottom: "10px"
                }}
              >
                <Typography component="span">
                  ADDED ON <Typography component="strong">{this.dateToText(value.created_at)}</Typography>
                </Typography>
                <IconButton
                  style={{
                    background: value.course_child_category.color,
                    padding: 5,
                    fontSize: 16,
                    borderRadius: 15,
                    color: "white"
                  }}
                >
                  <FaRegTrashAlt />
                </IconButton>
              </div>
              <div style={{ position: "relative" }}>
                <img
                  src={value.course_thumbnail}
                  style={{ borderRadius: "10.61px", height: 160 }}
                  alt="Launched Course"
                  height={160}
                  width={"100%"}
                />
                <img
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    top: "50%",
                    left: "50%"
                  }}
                  src={playIcon}
                  alt="Launched Course"
                  width={39}
                  height={39}
                />
              </div>
            </div>
            <div style={webStylesLocal.aboutCourse}>
              <div
                className="course-cat-name"
                style={{ borderColor: value.course_child_category.color }}
              >
                <CircleIcon
                  fontSize="inherit"
                  htmlColor={value.course_child_category.color}
                />
                <Typography variant="body1"  className="course-cat-name-text">
                    {value.course_child_category &&
                      value.course_child_category.name}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  columnGap: "10.21px",
                  alignItems: "center",
                  width: "45%"
                }}
              >
                <img
                  alt="rate"
                  src={star}
                  height={12}
                  width={12}
                />
                <Typography className="student-count-rating" variant="body2" component="span">
                  <Typography style={{ color: "black" }} variant="body2" component="strong">
                    {value.average_rating.Average_rating}
                  </Typography>{" "}
                  | {value.student_count} Students
                </Typography>
              </div>
              {this.stringToBoolean(value.is_wishlist) ? <FaHeart className="heartIconList heartIconList2" color="red" /> : <FaHeart className="heartIconList heartIconList2"
                color={"lightgrey"} />}
            </div>
            <Typography variant="h6" component="h3" className="course-short-desc">{value.course_name}</Typography>
          </CardContent>
          <div
            style={{
              marginTop: "1px",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                ...webStylesLocal.instructorNameMain,
                boxSizing: "border-box",
                background: value.course_child_category.color
              }}
            >
              <Typography className="instructor-name" variant="subtitle2" component="span">
                Instructor : {value.instructor_name}
              </Typography>
            </div>
          </div>
          <div style={webStylesLocal.addToCart}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                columnGap: "10px"
              }}
            >
              <Typography
                style={{
                  display: "inline-flex",
                  columnGap: "10px",
                  alignItems: "center",
                  margin: "auto 0",
                  fontWeight: "bold"
                }}
                className="course-price"
                variant="h5" component="h3"
              >
                <MdCurrencyRupee color="inherit" fontSize="inherit" />
                {value.first_year_price}/-
              </Typography>
            </div>
            <CartButton variant="contained" className={
              isPurchasedOrCart ?
                "added-cart-btn added-cart-text" :
                "add-cart-button add-cart-text"
            }>
              <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                {isPurchasedOrCart ? <FaCircleCheck fontSize="inherit" color="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                <Typography variant="body2" component="strong">
                  {isPurchased ? "Purchased" : isPurchasedOrCart ?
                    "Added" :
                    "Add to Cart"
                  }
                </Typography>
              </Typography>
            </CartButton>
          </div>
        </div>
        <div className="card-content active">
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <CardContent style={{ flexGrow: 1 }}>
              <div style={webStylesLocal.courseNameTop}>
                <Typography style={webStylesLocal.courseName} variant="body1" component="strong">
                  {value.course_child_category.name}
                </Typography>
                <IconButton
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "26.81px",
                    width: "25.1px",
                    height: "25.1px",
                    backgroundColor: "#FFF",
                    fontSize: "12px"
                  }}
                  data-test-id="removeFromWishlistTestId"
                  onClick={(e) =>
                    {
                      e.stopPropagation();
                      e.preventDefault();
                      this.removeFromWishlist(
                      item.attributes.courses.data.id,
                      index
                    )
                  }
                  }
                >
                  <IconButton
                    style={{
                      background: value.course_child_category.color,
                      padding: 5,
                      fontSize: 16,
                      borderRadius: 15,
                      color: "white"
                    }}
                  >
                    <FaRegTrashAlt />
                  </IconButton>
                </IconButton>
              </div>
              <div style={webStylesLocal.aboutCourse2}>
                <Typography variant="h6" component="h3" style={{ ...webStylesLocal.courseShortDescHover }}>
                  {value.course_name}
                </Typography>
                <Typography
                  className="description-wrapper"
                  variant="subtitle1" component="strong" 
                  style={{
                    ...webStylesLocal.fullDesc,
                    height: 100
                  }}
                >
                  {value.short_description}
                </Typography>
              </div>
            </CardContent>
            <div
              style={{
                justifyContent: "space-between",
                marginBottom: "20px"
              }}
            >
              <div style={webStylesLocal.aboutCourseLeftRight}>
                <div style={{ ...webStylesLocal.aboutCourseLeft }}>
                  <Typography component="span" style={{ display: "flex", marginBottom: "10.55px", alignItems: "center" }}>
                    <img src={star} width={12} height={12} />
                    <Typography variant="body2" component="span" style={webStylesLocal.chapterLectureTimeText}>{value.average_rating.Average_rating} | {value.student_count} Students</Typography>
                  </Typography>
                  <div style={webStylesLocal.leftRightLable}>
                    <Typography variant="subtitle2" component="span" style={webStylesLocal.instructorLang}>INSTRUCTOR</Typography>
                    <Typography variant="h6" component="span" style={webStylesLocal.instructorLangName}>{value.instructor_name}</Typography>
                  </div>
                  <div style={webStylesLocal.leftRightLable}>
                    <Typography variant="subtitle2" component="span" style={webStylesLocal.instructorLang}>LANGUAGE</Typography>
                    <Typography variant="h6" component="span" style={webStylesLocal.instructorLangName}>{value.language_name}</Typography>
                  </div>
                </div>
                <div className="divider">
                  <div className="line"></div>
                </div>
                <div style={webStylesLocal.aboutCourseRight}>
                  <div style={webStylesLocal.leftRightLables}>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={chapter} style={{ filter: "invert(100%)", width: 24, height: 24 }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {value.total_content} Chapters
                      </Typography>
                    </div>
                  </div>
                  <img style={{ marginBottom: "5px" }} src={dashedLine} />
                  <div style={webStylesLocal.leftRightLables}>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={lecture} style={{ filter: "invert(100%)", width: 24, height: 24 }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {value.course_class_count} Lectures
                      </Typography>
                    </div>
                  </div>
                  <img style={{ marginBottom: "6px" }} src={dashedLine} />
                  <div style={webStylesLocal.leftRightLables}>
                    <div style={webStylesLocal.chapterLectureTimeHead}>
                      <img src={time} style={{ filter: "invert(100%)", width: 24, height: 24 }} />
                      <Typography component="span" style={webStylesLocal.chapterLectureTimeText}>
                        {this.formatNumber(value.all_course_class_duration)} Minutes
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div style={webStylesLocal.addToCart}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    columnGap: "10px"
                  }}
                >
                  <Typography
                    style={{
                      display: "inline-flex",
                      columnGap: "10px",
                      alignItems: "center",
                      fontWeight: "bold"
                    }}
                    className="course-price"
                    variant="h5" component="h3"
                  >
                    <MdCurrencyRupee color="white" fontSize="inherit" />
                    {value.first_year_price}/-
                  </Typography>
                  {value.second_year_price && (
                    <Typography
                      style={{
                        display: "inline-flex",
                        columnGap: "10px",
                        alignItems: "center",
                        color: "lightgrey"
                      }}
                      className="course-price-discount"
                      variant="body1"
                    >
                      <MdCurrencyRupee color="white" fontSize="inherit" />
                      {value.second_year_price}/-
                    </Typography>
                  )}
                </div>
                <CartButton
                  data-test-id="addToCartTestId"
                  variant="contained"
                  onClick={(e) =>
                    {
                      e.stopPropagation();
                      e.preventDefault();
                      !isPurchasedOrCart && this.handleOpenCourseValidityPopup(isPurchasedOrCart, item, "trendingCourses", index);
                  }
                  }
                  className={
                    "add-cart-btn__wishlist add-cart-btn__wishlist-text"
                  }
                >
                  <Typography variant="body2" style={{ display: "inline-flex", alignItems: "center", gap: 3 }} component="strong">
                    {isPurchasedOrCart ? <FaCircleCheck fontSize="inherit" color="inherit" /> : <AddIcon color="inherit" fontSize="inherit" className="MuiTypography-caption" />}
                    <Typography variant="body2" component="strong">
                      {isPurchased ? "Purchased" : isPurchasedOrCart ?
                        "Added" :
                        "Add to Cart"
                      }
                    </Typography>
                  </Typography>
                </CartButton>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

    renderItemValidityDialog = () => {
        const {courseValidityDetails, selectedValidity, CourseValidityDialog} = this.state;
        if (!Object.keys(courseValidityDetails).length) return <></>;
        const details = courseValidityDetails.attributes.courses.data;
       const courseDescription = details.attributes.short_description;
       const course_name = details.attributes.course_name
        return (<CustomDialog 
        className={'dialogContainer'} 
        open={CourseValidityDialog} 
        onClose={this.onClose} 
        style={{ padding: 24 }}>
        <DialogTitle disableTypography className={'dialogTitleRoot'}>
          <Typography data-test-id="courseValidityTitle" className={'dialogTitle'}>
            {configJSON.courseValidityTitle}
          </Typography>
          <IconButton className={'closeButton'} onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={'dialogContent'}>
          <Typography variant="subtitle1" className={'subTitleCourseValidity'}>
            {course_name}: {courseDescription}
          </Typography>
          <div className={'buttonGroup'}>
            <Button
              data-test-id="1_year"
              variant="outlined"
              color="primary"
              className={`${'button'} ${selectedValidity === 1 ? 'selected' : ''}`}
              onClick={() => this.handleSelectValidity(1)}
            >
              <span className={'buttonLabel'}>
                {selectedValidity === 1 && <CheckIcon className={'checkIcon'} />}
                {configJSON.validity1Year}
              </span>
            </Button>
            {details?.attributes?.second_year_price ?
              <Button
                data-test-id="2_year"
                variant="outlined"
                color="primary"
                className={`${'button'} ${selectedValidity === 2 ? 'selected' : ''}`}
                onClick={() => this.handleSelectValidity(2)}
              >
                <span className={'buttonLabel'}>
                  {configJSON.validity2Year}
                  {selectedValidity === 2 && <CheckIcon className={'checkIcon'} />}
                </span>
              </Button>
              : null}
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between',padding: "16px 24px" }}>
          <Button className={'cancelButton'} onClick={this.onClose}>Cancel</Button>
          <Button 
          data-test-id="applyButton" 
          variant="contained" 
          className={'applyButton'} 
          onClick={() => 
          this.handleAddCart(
            false, 
            details.id, 
            details.type,
            "",
            selectedValidity
            )}>
            Apply
          </Button>
        </DialogActions>
      </CustomDialog>)
    }

  renderList() {
    return (
      <div style={webStylesLocal.bundleCourseMainLayout}>
        <Box sx={webStylesLocal.adminLayout}>
          <div style={webStylesLocal.headerContainer}>
            <div style={{ maxWidth: "800px", marginRight: "auto" }}>
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 20
                }}
              >
                <h1>My Wishlist</h1>
                <Chip
                  style={{ color: "white", background: "#FCB910" }}
                  label={`${this.state.wishlistList.length} ITEMS`}
                  data-test-id="itemsCounter"
                />
              </div>
              <p>
                Save and curate your desired courses, and easily plan your
                learning journey and access to your favorite educational
                resources with just a click.
              </p>
            </div>
            <div style={webStylesLocal.headerImage}>
              <img
                src={study}
                alt=""
                width={300}
                height={80}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "30px",
              gap: "10px"
            }}
          >
            <IconButton
              style={{
                backgroundColor:
                  this.state.listView === "card" ? "#00BE10" : "EEEFEF"
              }}
              data-test-id="tabView"
              onClick={() => this.changeDisplayListing("card")}
            >
              <MdOutlineWindow
                fontSize={"1.5rem"}
                color={this.state.listView === "card" ? "#FFF" : "#000"}
              />
            </IconButton>
            <IconButton
              style={{
                backgroundColor:
                  this.state.listView === "list" ? "#00BE10" : "EEEFEF"
              }}
              data-test-id="listIcon"
              onClick={() => this.changeDisplayListing("list")}
            >
              <TbLayoutList
                fontSize={"1.5rem"}
                color={this.state.listView === "list" ? "#FFF" : "#000"}
              />
            </IconButton>
          </div>
          <div
            data-test-id="backgroundTestID"
            style={{
              width: "100%",
              background: `url(${bg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              padding: "1.5rem 0",
              minHeight: 780
            }}
          >
            <div
              className={
                this.state.listView === "card"
                  ? "grid-card"
                  : "list-card"
              }
            >
              {this.state.wishlistList.map((item, index) => (
                <React.Fragment key={item.id}>
                  {this.state.listView === "card"
                    ? this.courseCard(item, index)
                    : this.courseList(item, index)}
                </React.Fragment>
              ))}
            </div>
            <div style={{ display: "flex", padding: "1rem", paddingBottom: 0 }}>
              {this.state.isLoading ? (
                <CircularProgress
                  style={{ margin: "auto", color: "#2a9e6d" }}
                />
              ) : (
                <>
                  <Button
                    data-test-id="loadMoreButtonTestId"
                    variant="contained"
                    style={{
                      background: "white",
                      borderRadius: 15,
                      boxShadow: "0px 2px 6px 0px #00000046",
                      margin: "auto",
                      color: "blue",
                      display: this.state.loadMoreDisabled
                        ? "none"
                        : "inline-flex"
                    }}
                    startIcon={<IoChevronDown color="black" />}
                    disabled={
                      this.state.loadMoreDisabled || this.state.isLoading
                    }
                    onClick={() => this.loadMore()}
                  >
                    Show More
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        {this.renderItemValidityDialog()}
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.closemodal}
          isOpen={this.state.isAlert}
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        <main
          style={{
            overflowX: "scroll"
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(60,116,129,1) 200px, rgba(255,255,255,1) 200px)",
              padding: "25px 0"
            }}
          >
            {this.renderList()}
            <FooterWeb id="footer" navigation={this.props.navigation} />
          </div>
        </main>
      </ThemeProvider>
    );
  }
}

// Customizable Area End
