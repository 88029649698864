import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Chip,
  Theme,
  createTheme,
  styled,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear'
import FooterWeb from "../../../components/src/Footer.web";
import { FaCircle, FaStar, FaRegTrashAlt } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { backArrowIcon, contactIcon } from "./assets";

const discountOption = [
  { type: 'Coupon', value: "I’VE A COUPON"},
  { type: 'Voucher', value: "I’VE A VOUCHER"},
  { type: 'Flat Discount', value: "I’VE A FLAT DISCOUNT"},
  { type: 'Points', value: "I’VE A POINTS"}
]

const Price = styled(Typography)({
  display: 'inline-block',
  position: 'relative',
  fontFamily: 'Inter',
  fontSize: '24px', 
  color: '#A49F9B',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px', 
    backgroundColor: 'black',
    top: '40%',
    left: 0,
    transform: 'rotate(170deg)',
    transformOrigin: 'center',
  }
});

const HeaderBox = styled(Box)({
  '.cartContainer': {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  '& .headerOrder': {
    background: '#4C8491',
    color: '#fff',
    padding: 24,
    // marginBottom: 16,
    display: 'flex',
    flexDirection: 'column' as const
  },
  '& .headerContent': {
    display: 'flex',
    gap: 60,
    '& .textMessageContent': {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 28.094337463378906,
      letterSpacing: -0.03443854674696922,
      color: '#ffffffff'
    }
  },
  '& .headerTitle': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 39.633052825927734,
    letterSpacing: -0.04858291149139404,
    color: '#ffffffff',
  },
  '.payButton': {
    background: "#fff",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 21.327117919921875,
    letterSpacing: 0,
    color: '#000000ff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#fff",
    }
  },
  '& .payText': {
    color: "#00BF11",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 21.327117919921875,
    letterSpacing: 0,
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize: 18
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 18
    },
  },
  '& .payAmount': {
    color: "#000",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 21.327117919921875,
    letterSpacing: 0,
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize: 18
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 16
    }
  },

})
const ShoppingCartBox = styled(Box)({
  '& .mainContainer': {
    flexGrow: 1,
    backgroundColor: '#f5f5f5',
  },
  '& .cartContainer': {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  '& .headerOrder': {
    // background: 'linear-gradient(135deg, rgb(60, 116, 129) 0%, rgb(21, 73, 85) 100%)',
    color: '#fff',
    padding: 24,
    // marginBottom: 16,
    display: 'flex',
    flexDirection: 'column' as const
  },
  '& .headerContent': {
    display: 'flex',
    gap: 60,
    '& .textMessageContent': {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 28.094337463378906,
      letterSpacing: -0.03443854674696922,
      color: '#ffffffff',
      '@media (min-width: 900px) and (max-width: 1280px)': {
        fontSize: 24
      }
    }
  },
  '& .headerTitle': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 39.633052825927734,
    letterSpacing: -0.04858291149139404,
    color: '#ffffffff',
  },
  '& .payButton': {
    background: "#fff",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 21.327117919921875,
    letterSpacing: 0,
    color: '#000000ff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: "#fff",
    }
  },
  '& .payText': {
    color: "#00BF11",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 21.327117919921875,
    letterSpacing: 0,
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize: 18
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 18
    },
  },
  '& .payAmount': {
    color: "#000",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 21.327117919921875,
    letterSpacing: 0,
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize: 18
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fintSize: 16
    }
  },
  '& .cartItemsList': {
    padding: 24,
  },
  '& .emptyCartText': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: -0.023,
    color: '#000',
    '@media (min-width: 1440px) and (max-width: 1920px)': {
      fontSize: 20
    },
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize:18
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize:16
    }
  },
  '& .validationText': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: -0.023,
    color: 'red',
    '@media (min-width: 1440px) and (max-width: 1920px)': {
      fontSize: 20
    },
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize:18
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize:16
    }
  },
  '& .validationText2': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: -0.023,
    color: '#000',
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize: 16
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 15
    }
  },
  '& .itemCard': {
    marginBottom: 16,
    border: '1px solid #e0e0e0',
    borderRadius: 12
  },
  '& .courseImage': {
    width: '100%',
    height: 180,
    borderRadius: 4,
    objectFit: "fill"
  },
  '& .orderCourseInfo': {
    paddingLeft: 16,
    flex: 1,
  },
  '& .starIconContainer': {
    gap: 16,
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 19.355146408081055,
    letterSpacing: 0,
    color: '#212121ff',
  },
  '& .promoCodeText': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 28.094,
    letterSpacing: -0.034,
    color: '#000',
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize: 25
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 25
    }
  },
  '& .priceSection': {
    textAlign: 'right' as const,
  },
  '& .orderPrice': {
    color: '#00be10',
    fontWeight: 'bold' as const,
    fontSize: 36,
    '& span':{
      color: "#000",
      fontWeight: 400
    },
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      fontSize: 32
    },
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 30
    }
  },
  '& .discountText': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 23.094337463378906,
    letterSpacing: -0.028309457004070282,
    color: '#000000ff',
    '& svg': {
      marginRight: 8,
    },
  },
  '& .applyDiscount': {
    backgroundColor: "#FFEFE7",
    borderBottomLeftRadius: '24px',
    borderSizing: "border-box",
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      paddingTop: '8px'
    }
  },
  '& .applyDiscountMain': {
    padding: '16px 40px',
    '@media (min-width: 1280px) and (max-width: 1366px)': {
      padding: '12px 30px'
    },
  },
  '& .checkoutButton': {
    cursor: 'pointer',
    marginTop: 24,
    backgroundColor: '#ff6f00',
    fontWeight: 600,
    fontSize: 23.327,
    letterSpacing: 0,
    color: '#fff',
    borderRadius: 24,
    fontFamily: 'Inter',
    '&:hover': {
      backgroundColor: '#ff6f00',
    },
  },
  '& .continueShopping': {
    marginLeft: 16,
    color: '#3f51b5',
    border: '1px solid #3f51b5',
    borderRadius: 40,
    padding: '8px 24px',
    fontFamily: 'Inter',
    fontSize: 18
  },
  '& .chipContainer':{
    cursor: 'pointer',
    fontFamily: 'Inter',
    '& .MuiChip-label': {
      fontFamily: 'Inter',
      fontSize: 16,
      background: 'transparent',
      '@media (min-width: 1440px) and (max-width: 1600px)': {
        fontSize: 16
      },
      '@media (min-width: 1330px) and (max-width: 1439px)': {
        fontSize: 16
      },
      '@media (min-width: 1280px) and (max-width: 1330px)': {
        fontSize: 14
      },
      '@media (min-width: 600px) and (max-width: 1280px)': {
        fontSize: 12
      }
    },
  },
  '& .couponAppliedDone': {
    '&:hover':{
      background: "initial",
    }
  },
  '& .itemValidity': {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    maxWidth: '70%',
    '& span': {
      marginLeft: 8,
      padding: 12,
      borderRadius: 4,
      fontSize: '0.875rem',
    },
  },
  '& .orderCourseName': {
    fontFamily: 'Inter',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: 0,
    color: '#212121',
    width: '70%',
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 20
    }
  },
  '& .coursePriceHeading': {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    '& .MuiListItemText-primary': {
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: 28.094,
      letterSpacing: -0.034,
      color: '#000',
      '@media (min-width: 1280px) and (max-width: 1366px)': {
        fontSize: 26
      },
      '@media (min-width: 900px) and (max-width: 1280px)': {
        fontSize: 24
      }
    },
    '& .MuiListItemText-secondary': {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 19.094337463378906,
      letterSpacing: -0.02340618520975113,
      color: '#000000ff',
      '@media (min-width: 1280px) and (max-width: 1366px)': {
        fontSize: 18
      },
      '@media (min-width: 900px) and (max-width: 1280px)': {
        fontSize: 16
      }
    }
  },
  '& .coursePriceText': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 24.094,
    letterSpacing: -0.034,
    color: '#000'
  },
  '& .discountContainer': {
    background: "#FFDCC8",
    padding: 12,
    borderRadius: 8,
    marginTop: 16,
  },
  '& .buttonGroup': {
    display: 'flex',
    gap: 12,
    '& .MuiButton-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)'
    }
  },
  '& .cancelButton': {
    cursor: "pointer",
    width: "50%",
    background: "#fff",
    borderRadius: 8,
    padding: "6px 12px",
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 15.457120895385742,
    letterSpacing: 0,
    color: '#212121ff',
    '&:hover':{
      background: "#fff",
    }
  },
  '& .applyButton': {
    cursor: 'pointer',
    width: "50%",
    background: "#FE502E",
    borderRadius: 8,
    padding: "6px 12px",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 15.457120895385742,
    letterSpacing: 0,
    color: '#fff',
    '&:hover':{
      background: "#FE502E",
    }
  },
  '& .totalText': {
    backgroundColor: '#000',
    margin: '20px',
    height: 3
  },
  '& .helpSection': {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    paddingBottom: 0,
    justifyContent: 'center'
  },
  '& .contactIcon': {
    width: 74,
    height: 74,
  },
  '& .helpText': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 19.068,
    letterSpacing: -0.221,
    color: '#000',
    marginLeft: 16,
    '@media (min-width: 900px) and (max-width: 1280px)': {
      fontSize: 17
    }
  },
  '& .orderSummary': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 38,
    letterSpacing: -0.04858291149139404,
    color: '#ffffffff',
    lineHeight: '100px'
  },
  '& .orderDeleteButton': {
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 18.763092041015625,
    letterSpacing: 0,
    color: '#ff0000ff',
    height: 48,
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 24,
    marginTop:"10px",
    padding: "6px 24px",
    '@media (min-width: 1440px) and (max-width: 1920px)': {
      width: 168
    }
  },
  '& .formControl': {
    width: '100%',
    margin: '8px 0',
  },
  '& .select': {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    border: '1px solid #e0e0e0',
    borderRadius: 4,
    '&:focus': {
      border: '1px solid #3f51b5',
    },
  },
  '& .chip': {
    width: '100%',
    background: '#fff',
    color: 'rgb(0, 191, 17)',
    display: 'flex',
    justifyContent: "flex-start",
    padding: 8,
    height: 40,
    border: "4px solid orange",
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 15.587013244628906,
    letterSpacing: 0,
    position: 'relative' as const,
    marginTop: 24
  },
  '& .chipDropDownIcon': {
    position: 'absolute' as const,
    right: 0,
    fontSize: 24,
    width: 40,
    height: 40,
    color: "gray",
    cursor: 'pointer'
  },
  '& .applyCouponInput': {
    borderRadius: 12,
    background: "#fff",
    fontFamily: 'Inter',
    '& .MuiFormLabel-root': {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 16.457120895385742,
      letterSpacing: 0,
      color: '#979797ff',
    },
    '& .MuiOutlinedInput-root':{
      borderRadius: 12,
    }
  },
  '& .shoopingCartDetailsContainer': {
    '@media (min-width: 1441px) and (max-width: 4400px)': {
      maxWidth: '1440px',
      margin: '0px auto'
    }
}
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
interface ShoppingCartData {
  id: string;
  attributes: {
    courseable_id: string;
    price: number;
    no_of_year: number;
    courses_shopping_carts: Array<{
      data: {
        attributes: {
          course_thumbnail?: string;
          bundle_course_thumbnail?: string;
          course_name?: string;
          bundle_name?: string;
          second_year_price?: number;
          course_child_category?: {
            color: string;
            name: string;
          };
          course_category?: {
            name: string;
          };
          average_rating?: {
            Average_rating: number;
            total_review_count: number;
          };
          rating?: number;
          student_count?: number;
          total_student?: number;
        };
        type: string;
      };
    }>;
  };
}

import CfRazorpayIntegration64 from "../../CfRazorpayIntegration64/src/CfRazorpayIntegration64.web";
import NotificationAlertMessage from "../../../components/src/NotificationAlertMessage";
export const configJSON = require("./config");
import HeaderWeb from "../../../components/src/Header.web";
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
  ShoppingCartOrder,
  ShoppingCartOrderItem,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { shoppingCartData, subtotal, total, discount,anchorEl ,discountPlaceHolder} = this.state;
    const mobileNumber:any = localStorage.getItem('mobileNumber')
    const mobile:any = mobileNumber ? JSON.parse(mobileNumber) : ""
    return (
      //Merge Engine End DefaultContainer
      <>
        <NotificationAlertMessage
          alertType={this.state.alertType}
          msg={this.state.alertMsg}
          onClose={this.oncloseAlert}
          isOpen={this.state.isAlert}
          data-test-id="notificationsAlertTestId"
        />
        <HeaderWeb id={""} navigation={this.props.navigation} />
        <ShoppingCartBox id="scrollContainer">
          <div className={'root'}>
          <div style={{ background: 'linear-gradient(to right, #4C8491 70%, rgb(253, 113, 0) 30%)' }}>
            <Grid container style={{ margin: '0px auto', maxWidth: 1440}}>
              <Grid item xs={12} md={8}>
                <HeaderBox>
                  <div className={'headerOrder'}>
                    <Box>
                      <Typography className={'headerTitle'}>
                        {configJSON.shoppingCartTitle}
                      </Typography>
                    </Box>
                    <Box className={'headerContent'}>
                      <Typography className='textMessageContent'>
                        {configJSON.shoppingCartSubTitle(shoppingCartData.length)}
                      </Typography>
                    </Box>
                  </div>
                </HeaderBox>
              </Grid>

              <Grid item xs={12} md={4} style={{ background: "#fff" }}>
                <Box padding={"0px 24px"} display={'flex'} alignItems={'end'} height={150} style={{ background: "#FD7100" }}>
                  <Typography variant="h3" className={'orderSummary'}>{configJSON.orderDetailsTitle}</Typography>
                </Box>
              </Grid>
            </Grid>
            </div>
            <Grid container className="shoopingCartDetailsContainer">
            <Grid item xs={12} md={8}>
              <div style={{ padding: '0px 24px' ,marginTop: 16}}>
                    <Typography className={'validationText'}>
                      {configJSON.noteInformation}
                    </Typography>
                  </div>
                  <List className={'cartItemsList'}>
                    {shoppingCartData?.length > 0 ? shoppingCartData.map((cartItem: ShoppingCartData, index: number) => {
                      const cartDetails = cartItem.attributes;
                      const coursesCartsId = cartItem.id;
                      const courseShoppingCart = cartDetails.courses_shopping_carts[0].data.attributes;
                      const courseType = cartDetails.courses_shopping_carts[0].data.type;
                      const courseImageSrc = courseType === "course" ?
                        courseShoppingCart.course_thumbnail :
                        courseShoppingCart.bundle_course_thumbnail;

                      const courseCategoryColor = courseType === "course" ?
                        courseShoppingCart?.course_child_category?.color :
                        "black";

                      const courseCategoryName = courseType === "course" ?
                        courseShoppingCart?.course_child_category?.name :
                        courseShoppingCart?.course_category?.name;

                      const courseRating = courseType === "course" ?
                        courseShoppingCart?.average_rating?.Average_rating :
                        courseShoppingCart.rating;

                      const totalStudents = courseType === "course" ?
                        courseShoppingCart.student_count :
                        courseShoppingCart.total_student;

                      const courseName = courseType === "course" ?
                        courseShoppingCart.course_name :
                        courseShoppingCart.bundle_name;

                      return (
                        <Card data-test-id="itemCard" className="itemCard" key={index}>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={12} md={3} lg={3}>
                                <img
                                  src={courseImageSrc || "https://via.placeholder.com/150"}
                                  alt="Course"
                                  className="courseImage"
                                />
                              </Grid>
                              <Grid item xs={12} md={9} lg={9} className="orderCourseInfo">
                                <Box display="flex" style={{ gap:16 }}>
                                  <Box display="flex" alignItems="center" style={{ gap:16 }} borderBottom={`2px solid ${courseCategoryColor}`} height={40}>
                                    <FaCircle className="circleIconList" style={{ fontSize: '1rem', margin: 0 }} color={courseCategoryColor} />
                                    <Typography variant="body2" style={{ fontFamily: 'Inter', fontWeight: 600, fontSize: 19.35, letterSpacing: 0, color: '#212121' }}>
                                      {courseCategoryName}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" alignItems="center" style={{ gap:8 }} className="starIconContainer">
                                    <FaStar style={{ color: "orange" }} />
                                    <Typography>
                                      {courseRating} <span style={{ color: "#929292", fontFamily: 'Inter', fontWeight: 600 }}>| {totalStudents || 0} Students</span>
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" style={{ gap:16 }} justifyContent="space-between">
                                  <Typography data-test-id="orderCourseName" className="orderCourseName" onClick={() => this.getNavigationMessage(cartDetails.courseable_id)} gutterBottom>
                                    {this.truncateText(courseName, 65)}
                                  </Typography>
                                  <Typography variant="h6" className="orderPrice">
                                    <span>₹</span> {cartDetails.price}/-
                                  </Typography>
                                </Box>
                                <Box display="flex" style={{ gap:16 }} justifyContent="space-between" alignItems="center">
                                  <div className="itemValidity" style={{ background: "#D8D8D8", gap: 8, borderRadius: 24, padding: 8 }}>
                                  <Chip
                                    variant="outlined"
                                    avatar={cartDetails.no_of_year === 1 ? <DoneIcon style={{ color: "#00BF11", background: "#fff", width: '2rem', height: '2rem', borderRadius: '50%' }} /> : <></>}
                                    label="1 YEAR VALIDITY"
                                    className={'chipContainer'}
                                    style={{
                                      width: '100%',
                                      color: cartDetails.no_of_year === 1 ? "#fff" : "#000",
                                      background: cartDetails.no_of_year === 1 ? "#00BF11" : "#fff",
                                      padding: 16,
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => this.handleChangeYearValidity(coursesCartsId , 1, index)}
                                  />
                                  {courseShoppingCart.second_year_price  && (
                                  <Chip
                                    data-test-id="yearValidity"
                                    variant="outlined"
                                    avatar={cartDetails.no_of_year === 2 ? <DoneIcon style={{ color: "#00BF11", background: "#fff", width: '2rem', height: '2rem', borderRadius: '50%' }} /> : <></>}
                                    label="2 YEAR VALIDITY"
                                    className={'chipContainer'}
                                    style={{
                                      width: '100%',
                                      color: cartDetails.no_of_year === 2 ? "#fff" : "#000",
                                      background: cartDetails.no_of_year === 2 ? "#00BF11" : "#fff",
                                      padding: 16,
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => this.handleChangeYearValidity(coursesCartsId,2, index)}
                                  />)}
                                  </div>
                                  <IconButton data-test-id="cartItemDeleteBtn" color="secondary" className="orderDeleteButton" style={{cursor:"pointer"}} onClick={() => this.deleteCourseCartItem(coursesCartsId)}>
                                    <FaRegTrashAlt style={{ fontSize: '1rem', marginRight: 12 }} /> {configJSON.deleteBtn}
                                  </IconButton>
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      )
                    }): (
                      <Box display={'flex'} justifyContent={'center'}>
                        <Typography variant="h4" style={{ fontSize: 32 }} className={'emptyCartText'}>{this.state.emptyCartMsg}</Typography>
                      </Box>
                    )}
                  </List>
                  <Button data-test-id="continueShopping" className={'continueShopping'} startIcon={<img src={backArrowIcon} width={20} height={20} />} onClick={this.handleContinueShopping}>
                    {configJSON.continueShoppingBtn}
                  </Button>
              </Grid>
              <Grid item xs={12} md={4} style={{ background: "#fff" }}>
                <div className={'applyDiscount'}>
                  <div className={'applyDiscountMain'}>
                    <Typography className={'promoCodeText'}>{configJSON.doYouHaveMsg}</Typography>
                    <Box className={'discountContainer'}>
                      <Typography variant="body2" className={'discountText'}>
                        {configJSON.applyDiscount}
                      </Typography>
                        <Chip
                          data-test-id="select_Discount"
                          label={this.state.selectDiscountType}
                          className={'chip'}
                          avatar={<DoneIcon style={{
                            background: 'rgb(0, 191, 17)',
                            borderRadius: '50%',
                            color: 'white',
                            padding: 2,
                            marginRight: 12
                          }} />}
                          deleteIcon={<RiArrowDropDownLine data-test-id="remove-coupon" className={'chipDropDownIcon'} style={{width: 40, height: 40, fontSize: '2rem' }}/>}
                          onDelete={this.handleDeleteClick}
                          onClick={this.handleDeleteClick}
                        />
                      {anchorEl && (
                      <Box style={{background: "#fff", borderRadius: 8 , marginTop: 2 }}>
                        {discountOption.filter(discount => discount.type !== this.state.discountPlaceHolder).map((item: {type: string, value: string }) => 
                      <MenuItem 
                      data-test-id="select_Discount_type"
                      onClick={() => this.handleSelectDiscountType(item.value, item.type)} 
                      style={{ fontFamily: "Inter"}}
                      >{item.value}</MenuItem>
                        )}
                      </Box>
                      )}
                       {this.state.appliedCoupon ? (
                        <>
                        <Box display={"flex"} justifyContent={'space-between'} style={{ padding: '20px 8px' }}>
                          <Box>
                            <Typography style={{ fontSize: 32, fontFamily: "Inter" , color: "#000" }}>{this.state.coupon} Applied</Typography>
                            <Typography style={{ fontSize: 24, fontFamily: "Inter", color: "#735B50" }}>{discount}{"₹"}  Discount</Typography>
                          </Box>
                          <Box style={{display:"flex", flexDirection:"column"}}>
                            <IconButton className="couponAppliedDone" disabled>
                              <DoneIcon style={{ color: "#fff", background: "#3CDB7F", width: '2rem', height: '2rem', borderRadius: '50%' }} />
                            </IconButton>
                            <IconButton data-test-id="cartItemDeleteBtn" color="secondary" className="orderDeleteButton" onClick={this.removeCouponCode}>
                                    <FaRegTrashAlt style={{ fontSize: '1rem', marginRight: 12 }} /> Remove
                                  </IconButton>
                          </Box>
                        </Box>
                        </>
                      ) : (
                          <>
                            <Box>
                              <TextField
                                data-test-id="applyCouponInput"
                                label={`Enter ${discountPlaceHolder} Code`}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                className={'applyCouponInput'}
                                value={this.state.coupon}
                                onChange={this.handleCouponValue}
                                error={this.state.couponError !== ""}
                              />
                              {
                                this.state.couponError !== "" && (
                                  <p style={{ color: 'red', marginTop: 0 }}>{this.state.couponError}</p>
                                )
                              }
                            </Box>
                            <Box className={'buttonGroup'}>
                              <Button data-test-id="cancelButton" variant="outlined" className={'cancelButton'} onClick={this.handleCancelCoupon}>{configJSON.cancelBtn}</Button>
                              <Button 
                              data-test-id="applyButton" 
                              className={'applyButton'} 
                              onClick={this.applyCouponCode}
                              disabled={this.state.coupon == ""}>{configJSON.applyBtn}</Button>
                            </Box>
                          </>
                      )}
                    </Box>
                  </div>
                  <Box style={{ marginTop: 24, padding: "0px 24px" }}>
                    <ListItem style={{ padding: "0px 24px" }}>
                      <ListItemText primary="Actual Price" className={'coursePriceHeading'} />
                      <Price>₹ {subtotal.toFixed(2)}</Price>
                    </ListItem>
                    <ListItem style={{ padding: "0px 24px" }}>
                      <ListItemText primary="Offer Price" className={'coursePriceHeading'} />
                      <Typography className="coursePriceText" variant="body1">₹ {total.toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem style={{ padding: "0px 24px" }}>
                      <ListItemText primary="Discount" className={'coursePriceHeading'} />
                      <Typography data-test-id="discount" className="coursePriceText" variant="body1">₹ {typeof(discount) === 'number' ? discount.toFixed(2): discount}</Typography>
                    </ListItem>

                    <Divider className={'totalText'} />

                    <ListItem>
                      <ListItemText primary="Total" secondary={"including all taxes"} className={'coursePriceHeading'} />
                      <Typography variant="h6" className="coursePriceText" style={{ fontWeight: 600 }}>₹ {total.toFixed(2)}</Typography>
                    </ListItem>
                  </Box>
                  <Box style={{ padding: '16px 24px' }}>
                    <CfRazorpayIntegration64
                      navigation={this.props.navigation}
                      id={""}
                      order_id={this.state.shopping_cart_id}
                      voucher={this.state.appliedCoupon ? this.state.discountPlaceHolder : ""}
                      code={this.state.appliedCoupon ? this.state.coupon : ""}
                    />
                  </Box>

                  <Typography className={'validationText2'} style={{ marginTop: 16, padding: '0px 12px', textAlign: 'center' }}>
                    {configJSON.noteInformation}
                  </Typography>
                  <Box className={'helpSection'}>
                    <img src={contactIcon} className={'contactIcon'} />
                    <Typography className={'helpText'}>
                      {configJSON.helpText} <span>{mobile}</span>
                    </Typography>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>
          <Box style={{ marginTop: 50 }}>
            <FooterWeb navigation={this.props.navigation} id={''} />
          </Box>
        </ShoppingCartBox>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
// Customizable Area End
